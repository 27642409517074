<ng-container *ngIf="!isLoading">
  <div class="row">
    <div class="col">
      <div class="card mb-3">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div>
              <a [routerLink]="'/wallets/' + wallet.id + '/actions'">
                <i class="la la-arrow-left mr-2"></i>
              </a>
            </div>
            <h4 class="font-weight-bold mb-0">
              <ng-container *ngIf="isCreateMode"> New Action </ng-container>
              <ng-container *ngIf="!isCreateMode">
                Edit Action - {{ action.name }}
              </ng-container>
            </h4>
            <span
              *ngIf="action.isPublishable()"
              class="badge badge-success ml-2"
            >
              complete</span
            >
            <span
              *ngIf="!action.isPublishable()"
              class="badge badge-warning ml-2"
            >
              to complete</span
            >
            <div class="ml-auto">
              <button
                [disabled]="action.status === 1"
                (click)="delete()"
                class="btn btn-sm btn-danger"
              >
                - delete
              </button>
            </div>
          </div>
        </div>

        <!-- navigation -->
        <ul class="nav nav-tabs px-3">
          <li class="nav-item">
            <a
              class="nav-link small cursor-pointer"
              (click)="setTab('video')"
              [class.active]="currentTab === 'video'"
              >Video</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link small cursor-pointer"
              (click)="setTab('action')"
              [class.active]="currentTab === 'action'"
              [class.disabled]="shouldDisableTab()"
              >Description</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link small cursor-pointer"
              (click)="setTab('themes')"
              [class.active]="currentTab === 'themes'"
              [class.disabled]="shouldDisableTab()"
              >Themes</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link small cursor-pointer"
              (click)="setTab('results')"
              [class.active]="currentTab === 'results'"
              [class.disabled]="shouldDisableTab()"
              >Results</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link small cursor-pointer"
              (click)="setTab('budget')"
              [class.active]="currentTab === 'budget'"
              [class.disabled]="shouldDisableTab()"
              >Budget</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link small cursor-pointer"
              (click)="setTab('location')"
              [class.active]="currentTab === 'location'"
              [class.disabled]="shouldDisableTab()"
              >Location</a
            >
          </li>
        </ul>

        <!-- form -->
        <form [formGroup]="form">
          <div class="card-body">
            <ng-container *ngIf="currentTab === 'video'">
              <ng-container *ngIf="action.files.length > 0">
                <div
                  *ngFor="
                    let file of action.files | slice: 0:1;
                    let idx = index
                  "
                  class="d-flex justify-content-center p-2"
                >
                  <app-file-display
                    [file]="file"
                    [cssClass]="'file md' + (idx === 0 ? ' active' : '')"
                  ></app-file-display>
                  <div class="d-flex justify-content-around mt-1 ml-1">
                    <div>
                      <button
                        class="btn btn-xs btn-danger"
                        (click)="removeFile(idx)"
                      >
                        <i class="la la-remove"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>

              <app-file-upload
                *ngIf="action.files.length === 0"
                [action]="action"
                (filesUploading)="filesUploading = true"
                (filesUploaded)="refresh($event)"
              ></app-file-upload>

              <small class="form-text notice">
                {{ videoInfoMsg }}
              </small>
            </ng-container>

            <ng-container *ngIf="currentTab === 'action'">
              <div class="row">
                <div class="form-group col-12">
                  <label> Name of the action </label>
                  <input
                    class="form-control form-control-sm"
                    formControlName="name"
                    id="name"
                    type="text"
                  />
                  <small class="form-text notice">
                    Distinct name of your action like "Beach cleanup, Penny's
                    bay, 3rd July 2022"
                  </small>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-6">
                  <label> Date of action </label>
                  <input
                    class="form-control form-control-sm"
                    type="date"
                    placeholder="yyyy-mm-dd"
                    name="dp"
                    onclick="this.showPicker()"
                    formControlName="actionDate"
                  />
                  <small class="form-text notice">
                    Date - or start date - of your action
                  </small>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-12">
                  <label> Objective of the action </label>
                  <textarea
                    class="form-control form-control-sm"
                    formControlName="objective"
                    id="objective"
                    rows="5"
                  ></textarea>
                  <small class="form-text notice">
                    Short summary of the challenge or the issue dealt with by
                    this action.
                  </small>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="currentTab === 'themes'">
              <div class="form-group">
                <div class="row">
                  <div *ngFor="let theme of themes" class="col-lg-6 col-xl-4">
                    <div
                      (click)="toggleTheme(theme)"
                      [class.active]="haveTheme(theme)"
                      class="card theme"
                    >
                      <div class="card-body d-flex flex-row align-items-center">
                        <div class="ico mr-2">
                          <i class="la {{ theme.icon }} la-2x"></i>
                        </div>
                        <div class="small">{{ theme.name }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="currentTab === 'results'">
              <div class="row">
                <div class="form-group col-12">
                  <label> Action description </label>
                  <textarea
                    class="form-control form-control-sm"
                    formControlName="description"
                    id="description"
                    rows="5"
                  ></textarea>
                  <small class="form-text notice">
                    Description of the work done, people involved, duration,
                    struggles, etc.
                  </small>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-12">
                  <label> Result </label>
                  <textarea
                    class="form-control form-control-sm"
                    formControlName="result"
                    id="result"
                    rows="5"
                  ></textarea>
                  <small class="form-text notice">
                    Obtained results - using figures when possible; should be
                    related to the original objective
                  </small>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="currentTab === 'budget'">
              <div class="row">
                <div class="form-group col-6">
                  <label> Action cost range </label>
                  <ngx-select
                    optionTextField="name"
                    optionValueField="id"
                    [items]="values"
                    formControlName="value"
                    size="small"
                  ></ngx-select>
                  <small class="form-text notice">
                    Range of budget needed to realize that action
                  </small>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-12">
                  <label> Budget usage description </label>
                  <textarea
                    class="form-control form-control-sm"
                    formControlName="budget"
                    id="budget"
                    rows="5"
                  ></textarea>
                  <small class="form-text notice">
                    Summary of expenses and budget usage for that action
                  </small>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-6">
                  <label> Fees % </label>
                  <input
                    class="form-control form-control-sm"
                    formControlName="fees"
                    id="fees"
                    type="number"
                  />
                  <small class="form-text notice">
                    Amount of fees in percentage you're willing to give away to
                    other profile that will promote your action
                  </small>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="currentTab === 'location'">
              <app-wallet-actions-edit-location
                (locationData)="getLocationData($event)"
                [action]="action"
                [activeTab]="currentTab"
              ></app-wallet-actions-edit-location>
            </ng-container>
          </div>
        </form>

        <!-- buttons -->
        <div
          *ngIf="!isCreateMode"
          class="d-flex justify-content-around align-items-center mb-3"
        >
          <button
            (click)="save()"
            [disabled]="
              !form.valid || (!form.pristine && !form.dirty && isSubmitted)
            "
            class="btn btn-sm btn-taamana"
          >
            <i *ngIf="!isSubmitted" class="la la-save"></i>
            <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
            Save
          </button>

          <button
            *ngIf="action.status === 1 && action.isPublishable()"
            [routerLink]="
              '/wallets/' + wallet.id + '/actions/' + action.id + '/submit'
            "
            class="btn btn-sm btn-taamana mr-2"
          >
            submit
            <i class="la la-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<app-loader *ngIf="isLoading"></app-loader>
