<div class="content">
  <div class="row">
    <div class="col-12">
      <h2 class="font-weight-bold">Confirmation</h2>
    </div>
    <div class="col-12">
      <div class="small text-muted">
        Thanks for registering to taamana !
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12 d-flex justify-content-center">
      <button class="btn wide btn-sm btn-taamana" [routerLink]="'/main/login'">
        Sign in
        <i class="la la-arrow-right"></i>
      </button>
    </div>
  </div>

</div>
