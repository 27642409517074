import {Component, OnDestroy} from '@angular/core';
import {AuthService, CatalogService, NotificationsService, QueryService, WalletService} from '../../../shared/services';
import {ActionModel, WalletModel} from '../../../shared/models';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-pub-wallet-details',
  templateUrl: './details.component.html'
})
export class WalletDetailsComponent implements OnDestroy {
  isAdmin = false;
  isConnected = false;

  wallet: WalletModel;
  actions: ActionModel[];
  country: { name: string; code: string; };

  loaderSubscription$: Subscription;

  isLoading = true;

  constructor(
    private authService: AuthService,
    private walletService: WalletService,
    private route: ActivatedRoute,
    private queryService: QueryService,
    private catalogService: CatalogService,
    // public services
    public notificationService: NotificationsService
  ) {
    this.loaderSubscription$ = this.route.params.subscribe(async params => {
      this.loadItem(+params.id);
    });
    this.isAdmin = this.authService.isAdmin();
    this.isConnected = this.authService.isConnected();
  }

  ngOnDestroy(): void {
    this.loaderSubscription$.unsubscribe();
  }

  async loadItem(itemId: number): Promise<void> {
    this.isLoading = true;
    this.wallet = await this.walletService.loadWallet(itemId);
    this.actions = await this.walletService.loadStream(itemId);
    this.country = await this.catalogService.getCountry(this.wallet.country);
    this.isLoading = false;
  }
}
