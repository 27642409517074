<ng-container *ngIf="!isLoading">

  <div class="row">
    <div class="col">

      <div class="card mb-3">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <h4 class="font-weight-bold mb-0">
              Validation jobs
            </h4>
            <div class="ml-auto">
              <button [routerLink]="'/wallets/' + wallet.id + '/ratings/new'" class="btn btn-sm btn-taamana">
                + validation
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-3">
        <div class="list-group list-group-flush">
          <div *ngIf="ratings.length === 0" class="list-group-item">

            <h6 class="card-title">
              No validations found
            </h6>
            <p>Maybe you want to
              <a [routerLink]="'/wallets/' + wallet?.id + '/ratings/new'" class="taamana">
                give it a try
              </a> and earn some DURA ?
            </p>

          </div>

          <div
            *ngFor="let rating of ratings"
            class="list-group-item action"
            [routerLink]="rating.action.status===3 ?
              '/wallets/' + rating.action.producer.id + '/actions/' + rating.action.id :
              null">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <app-action-header
                  [action]="rating.action"
                  [wallet]="null"
                  [size]="'sm'"
                  [showStatus]="false"
                  [showScore]="false"
                  [showStars]="false"
                ></app-action-header>
                <app-wallet-header
                  [wallet]="rating.action.producer"
                  [size]="'xs'"
                  [showScore]="false"
                ></app-wallet-header>
              </div>
              <app-action-score [score]="rating.score" [size]="'xs'"></app-action-score>
            </div>

            <div class="smaller text-muted mt-1">{{ rating.stamp }}</div>

            <div class="d-flex flex-wrap">

              <div class="p-1 px-2 rounded bg-light small mt-1 mr-2">
                <span>{{rating.efficiency}} efficiency</span>
              </div>

              <div class="p-1 px-2 rounded bg-light small mt-1 mr-2">
                <span>{{rating.efficiency}} efficiency</span>
              </div>

              <div class="p-1 px-2 rounded bg-light small mt-1 mr-2">
                <span>{{rating.effectiveness}} effectiveness</span>
              </div>

              <div class="p-1 px-2 rounded bg-light small mt-1 mr-2">
                <span>{{rating.quality}} quality</span>
              </div>

              <div class="p-1 px-2 rounded bg-light small mt-1 mr-2">
                <span>{{rating.commongoods}} common goods</span>
              </div>

              <div class="p-1 px-2 rounded bg-light small mt-1 mr-2">
                <span>{{rating.videoquality}} video quality</span>
              </div>

            </div>
          </div>
        </div>


        <!--        <div class="card-body">-->

        <!--          <div *ngIf="ratings.length === 0" class="alert alert-info">-->
        <!--            No validation found-->
        <!--          </div>-->

        <!--        </div>-->
      </div>
    </div>
  </div>

</ng-container>

<ng-container *ngIf="isLoading">
  <app-loader></app-loader>
</ng-container>
