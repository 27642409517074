import {Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ActionModel, ShareModel, WalletModel} from 'src/app/shared/models';

@Component({
  selector: 'app-support-confirm',
  templateUrl: './confirm.component.html'
})
export class SupportConfirmModalComponent {
  action: ActionModel;
  wallet: WalletModel;
  share: ShareModel;

  /**
   * @param bsModalRef
   */
  constructor(
    public bsModalRef: BsModalRef,
  ) {
  }

  /**
   * callBack & Cancel
   */
  callbackFn = () => {};
  cancelFn = () => {};
}
