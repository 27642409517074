import { Component, Input, Output, EventEmitter } from '@angular/core';
import { QueryService } from 'src/app/shared/services/query.service';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { WalletModel } from '../../models';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-media-upload',
  templateUrl: 'upload.component.html'
})
export class MediaUploadComponent {

  @Input() item: WalletModel;
  @Input() apiRoute;
  @Input() formGroup: FormGroup;
  @Input() controlName: string;
  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();

  // form instance
  form!: FormGroup;

  // File Object to upload
  fileToUpload: File | null = null;

  // Is file upload complete
  uploaded = false;
  storyId: any;

  //
  isUploading = false;

  // Upload server response
  return: any = [];

  constructor(
    private queryService: QueryService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
  }

  handleFileInput(files: FileList): void {
    this.fileToUpload = files.item(0);
  }

  onFileChanged(event): void {
    const file = event.target.files[0];
  }

  postFile(fileToUpload: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append(this.controlName, fileToUpload, fileToUpload.name);

    return this.queryService.q('POST', this.apiRoute + this.item.id, formData);
  }

  post(): void {
    this.isUploading = true;
    this.postFile(this.fileToUpload).subscribe(ret => {
      this.uploaded = true;
      this.isUploading = false;
      this.formGroup.get(this.controlName).reset();
      this.refresh.emit();
      this.toastr.success('File uploaded with success');
    }, error => {
      this.uploaded = false;
      this.isUploading = false;
      this.toastr.success('Error');
    });
  }
}
