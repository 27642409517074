import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-wallet-deposit-failure',
  templateUrl: './failure.component.html'
})
export class WalletDepositFailureComponent {

  walletId: number;

  constructor(
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(async params => {
      this.walletId = +params.id;
    });
  }

}
