<ng-container *ngIf="!isLoading">
  <div class="card">
    <div class="card-body">

      <!-- TITLE -->
      <div class="card-title d-flex align-items-center justify-content-between">
        <div class="font-weight-bold">Similar collections</div>
        <a [routerLink]="'/wallets'" class="small ml-1">see more <i class="la la-arrow-right"></i></a>
      </div>

      <!-- POOLS -->
      <div *ngFor="let wallet of wallets"
           class="d-flex flex-row justify-content-between align-items-center p-2">
        <div [routerLink]="'/wallets/' + wallet.id" class="d-flex align-items-center text-truncate cursor-pointer">
          <app-avatar [wallet]=" wallet" [size]="'xs'"></app-avatar>
           <span class="small ml-1">{{wallet.name}}</span>
        </div>
        <div class="score sm py-1 px-2 text-nowrap">
          <i class="las la-star score"></i> {{wallet.score}}
        </div>
      </div>

    </div>
  </div>
</ng-container>

<ng-container *ngIf="isLoading">
  <app-loader></app-loader>
</ng-container>
