<ng-container *ngIf="!isLoading">

  <div class="row mb-2">
    <div class="col">
      <div class="card mb-3">

        <!-- header -->
        <div class="card-body border-bottom">
          <div class="d-flex align-items-center">
            <h4 class="font-weight-bold mb-0">
              <ng-container *ngIf="wallet.id===0">Create a new Profile</ng-container>
              <ng-container *ngIf="wallet.id>0">Profile properties</ng-container>
            </h4>
          </div>
        </div>

        <!-- content -->
        <div class="card-body">
          <form [formGroup]="form">
            <div class="row">
              <div class="form-group col-8">
                <label>Profile name</label>
                <div>
                  <input
                    [ngClass]="{ 'is-invalid': isSubmitted && form.get('name')?.invalid }"
                    class="form-control form-control-sm" formControlName="name" id="name" type="text"/>
                    <small class="form-text notice">
                      This is the public name for your Profile.
                    </small>
                </div>
              </div>
              <div class="form-group col-4">
                <label>Display color</label>
                <div>
                  <input
                    [ngClass]="{ 'is-invalid': isSubmitted && form.get('color')?.invalid }"
                    class="form-control form-control-sm" formControlName="color" id="color" type="color"/>
                  <small class="form-text notice">
                    Background color of your avatar.
                  </small>
                </div>
              </div>
              <div class="form-group form-check col-12">
                <label>Profile type</label>
                <div class="row">
                  <div class="col-6">
                    <input formControlName="type" type="radio" id="type_1" [value]="1">
                    <label for="type_1" class="checkbox">Individual</label>
                  </div>
                  <div class="col-6">
                    <input formControlName="type" type="radio" id="type_2" [value]="2">
                    <label for="type_2" class="checkbox">Group</label>
                  </div>
                </div>
                <small class="form-text notice">
                  Is this Profile created for a community or for your personal use ?
                </small>
              </div>
              <div class="form-group col-12">
                <label>Description</label>
                <div>
                  <textarea
                    [ngClass]="{ 'is-invalid': isSubmitted && form.get('description')?.invalid }"
                    class="form-control form-control-sm" formControlName="description" id="description"
                    type="text"></textarea>
                  <small class="form-text notice">
                    This will be displayed on your Profile and will help
                    people understand your challenges and concerns
                  </small>
                </div>
              </div>
              <div class="form-group col-12">
                <label>Location</label>
                <div>
                  <ngx-select
                    optionTextField="name"
                    optionValueField="code"
                    [items]="countries"
                    formControlName="country"
                  ></ngx-select>
                  <small class="form-text notice">
                    Please tell us where you are located ?
                  </small>
                </div>
              </div>
            </div>
          </form>

          <!-- buttons -->
          <div class="d-flex justify-content-around align-items-center">
            <button (click)="save()" [disabled]="!form.valid || !form.pristine && !form.dirty && isSubmitted"
                    class="btn btn-sm btn-taamana">
              <i *ngIf="!isSubmitted" class="la la-save la-lg"></i>
              <i *ngIf="isSubmitted" class="la la-spinner la-spin la-lg"></i>
              Save
            </button>
          </div>

        </div>

      </div>
    </div>
  </div>
</ng-container>

<app-loader *ngIf="isLoading"></app-loader>

