<ng-container *ngIf="!isLoading">

  <!-- Current wallet -->
  <div class="card mb-3">

    <div class="card-body">
<!--      <h5 class="font-weight-bold mb-0">Your profiles</h5>-->
      <div class="btn-group w-100" dropdown>
        <button id="button-animated" dropdownToggle type="button"
                class="btn btn-sm btn-light"
                aria-controls="dropdown-animated">
          <div class="d-flex align-items-center justify-content-between">
            <app-avatar [wallet]="currentWallet" [size]="'md'" showName="true"></app-avatar>
          </div>
        </button>
        <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu"
            role="menu" aria-labelledby="button-animated">
          <li *ngFor="let wallet of wallets" role="menuitem">
            <a (click)="changeWallet(wallet)" class="dropdown-item">
              <div class="d-flex align-items-center justify-content-between">
                <app-avatar [wallet]="wallet" [size]="'xs'" showName="true"></app-avatar>
                <div class="score xs ml-2 py-1 px-1 text-nowrap">
                  <i class="las la-star score"></i> {{wallet.score}}
                </div>
              </div>
            </a>
          </li>
          <li class="divider dropdown-divider"></li>
          <li role="menuitem">
            <a [routerLink]="'/wallets/0/edit'" class="small dropdown-item">
              Create a new Profile
            </a>
          </li>
        </ul>
      </div>

      <div class="d-flex justify-content-between align-items-center pt-2 mt-2 ml-1">
        <div class="mdtext w-100">
          <a [routerLink]="'/wallets/' + currentWallet?.id + '/transactions'">
            <div class="d-flex justify-content-between align-items-center">
              <div>DURA balance</div>
              <div>
                <span
                  class="font-weight-bold"
                  [class.text-danger]="currentWallet?.balance<0"
                  [class.text-success]="currentWallet?.balance>=0">
                  D {{currentWallet?.balance}}
                </span>
              </div>
            </div>
          </a>
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-center pt-2 ml-1">
        <div class="mdtext">
          <a [routerLink]="'/wallets/' + currentWallet?.id + '/edit'">
            Profile properties
          </a>
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-center pt-2 ml-1">
        <div class="mdtext">
          <a [routerLink]="'/wallets/' + currentWallet?.id + '/tokens'">
            Invitation tokens
          </a>
        </div>
      </div>

    </div>

    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <div class="font-weight-bold">
          Actions
        </div>
      </div>

      <div class="d-flex align-items-center pt-2 ml-1">
        <div class="mdtext">
          <a [routerLink]="'/wallets/' + currentWallet?.id + '/actions'">
            Submit actions
          </a>
        </div>
      </div>

      <div class="d-flex align-items-center pt-2 ml-1">
        <div class="mdtext">
          <a [routerLink]="'/wallets/' + currentWallet?.id + '/ratings'">
            Validate actions
          </a>
        </div>
        <div
          *ngIf="notificationService.haveRatings()"
          class="ml-2 notification mark"></div>
      </div>

    </div>

    <div class="card-body">

      <div class="d-flex justify-content-between align-items-center">
        <div class="font-weight-bold">
          Community
        </div>
      </div>

      <app-loader *ngIf="isNetworkLoading"></app-loader>

      <ng-container *ngIf="!isNetworkLoading">
        <div *ngIf="network.length === 0" class="pt-2 ml-1">
          <div class="mdtext text-muted">
            No Profiles
          </div>
        </div>

        <div *ngFor="let wallet of network" class="pt-2 ml-1">
          <a [routerLink]="'/wallets/' + wallet.id">
            <div class="d-flex align-items-center justify-content-between">
              <app-avatar
                [wallet]="wallet"
                [size]="'xs'"
                showName="true"
                [notifications]="notificationService.getNotifications(wallet.id)"></app-avatar>
              <div class="score xs py-1 px-1 text-nowrap">
                <i class="las la-star score"></i> {{wallet.score}}
              </div>
            </div>
          </a>
        </div>
      </ng-container>

    </div>
  </div>

  <!-- Feedback & utils -->
  <div class="card mb-3">
    <div class="card-body">
      <ul class="nav flex-column">
        <li>
          <a class="mdtext" href="mailto:axavier@taamana.com" target="_blank">
            <i class="las la-comment la-lg mr-1"></i> Question ? feedback ?
          </a>
        </li>
      </ul>
    </div>
  </div>

</ng-container>

<app-loader *ngIf="isLoading"></app-loader>

