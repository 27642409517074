<div class="content">

  <div class="row">
    <div class="col-12 d-flex flex-column justify-content-center">
      <div>
        <span class="small text-muted">You are invited by</span>
      </div>
      <div class="border rounded bg-white p-2">
        <app-wallet-header
          [wallet]="registerService.host"
          [showFollow]="false"
          [size]="'md'"></app-wallet-header>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <h2 class="font-weight-bold">Personal Details</h2>
    </div>

    <div class="col-12">
      <div class="small text-muted">
        Share some personal information about you
      </div>
    </div>
    <div class="col-6 mt-3">
      <label>First name</label>
      <div>
        <input [(ngModel)]="registerService.user.firstName" class="form-control" type="text">
      </div>
    </div>
    <div class="col-6 mt-3">
      <label>Last name</label>
      <div>
        <input [(ngModel)]="registerService.user.lastName" class="form-control" type="text">
      </div>
    </div>
    <div class="col-12 mt-3">
      <label>Email</label>
      <div>
        <input [(ngModel)]="registerService.user.email" class="form-control" type="email">
      </div>
    </div>
    <div class="col-12 mt-3">
      <label>Password</label>
      <div class="d-flex justify-content-between align-items-center">
        <input *ngIf="modeHide" [(ngModel)]="registerService.user.password" class="form-control" type="password">
        <input *ngIf="!modeHide" [(ngModel)]="registerService.user.password" class="form-control" type="text">
        <i class="la la-lg ml-2" [class.la-eye]="modeHide" [class.la-eye-slash]="!modeHide" (click)="toggleModeHide()"></i>
      </div>
    </div>

  </div>

  <div class="row mt-3">
    <div class="col-12 d-flex justify-content-center">
      <button [disabled]="isDisabled()" class="btn wide btn-sm btn-taamana" (click)="checkEmail()">
        next
        <i class="la la-arrow-right"></i>
      </button>
    </div>
  </div>

</div>
