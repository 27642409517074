<ng-container *ngIf="!isLoading">
  <div class="row">
    <div class="col">

      <!-- header -->
      <div class="card mb-3">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <h4 class="font-weight-bold mb-0 mr-2">
              Validation -
            </h4>
            <app-action-header
              [action]="action"
              [showScore]="false"
              [showStars]="false"
              [showStatus]="false"
            ></app-action-header>
          </div>
        </div>
      </div>

      <!-- content -->
      <div class="card mb-3">
        <div class="card-body">
          <h6 class="card-title">
            All done !
          </h6>

          <p class="card-text">
            Thank you for your feedback.
          </p>

          <p class="card-text">
            As a reward for your time and participation to the validation process, we are pleased to give you
            <span class="font-weight-bold">D {{ rating.reward }}</span>.
          </p>

          <!-- buttons -->
          <div class="d-flex justify-content-around align-items-center">
            <div>
              <button [routerLink]="'/wallets/' + wallet.id + '/actions'"
                      class="btn btn-sm btn-light">
                <i class="la la-arrow-left"></i> back to validations list
              </button>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</ng-container>

<app-loader *ngIf="isLoading"></app-loader>
