import { Component, OnInit } from '@angular/core';
import { AuthService, WalletService } from '../../services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  notifications: any[];
  isLoading = true;

  isAdmin: boolean;
  isConnected: boolean;

  constructor(
    private authService: AuthService,
    // Used in template
    public walletService: WalletService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.initUser(false, true);
  }

  async initUser(silent = false, forceReload = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }

    this.isAdmin = this.authService.isAdmin();
    this.isConnected = this.authService.isConnected();

    this.isLoading = false;
  }

  logout(): void {
    this.walletService.resetWallets();
    this.authService.logout();
  }
}
