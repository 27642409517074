<div class="content">
  <div class="row">
    <div class="col-12">
      <h2 class="font-weight-bold">Choose your country</h2>
    </div>
    <div class="col-12">
      <div class="notice">
        Please tell us in which country you are living and we will help you find the nearest pools
      </div>
    </div>
    <div class="col-12 mt-3">
      <div>
        <ngx-select
          optionTextField="name"
          optionValueField="code"
          [items]="countries"
          [(ngModel)]="registerService.user.country"
        ></ngx-select>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12 d-flex justify-content-center">
      <button [disabled]="isDisabled()" class="btn wide btn-sm btn-taamana" [routerLink]="'/register/perso'">
        next
        <i class="la la-arrow-right"></i>
      </button>
    </div>
  </div>

</div>
