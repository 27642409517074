
export class DataPayloadModel {
  code: string;
  data: any;

  constructor(data) {
    this.code = data.name ?? '';
    this.data = data.data ?? [];
  }
}

