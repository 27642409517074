<!-- Header Left -->
<nav class="navbar flex-md-nowrap p-4">
  <div class="container d-flex align-items-center" routerLink="/home">
    <div>
      <img class="avatar sm mr-2" src="../../../../assets/img/logo.png" style="margin-top: -0.2rem;"/>
      <h1 class="navbar-brand m-0">
        Taamana
      </h1>
    </div>
  </div>
</nav>
