<!-- Header Right -->
<nav class="navbar flex-md-nowrap p-4">
  <div class="container d-flex align-items-center justify-content-end w-100">
    <div class="ml-auto">
      <button [routerLink]="'/main/login'" class="btn btn-sm btn-taamana">sign in</button>
      or
      <button [routerLink]="'/register'" class="btn btn-sm btn-taamana">register</button>
    </div>
  </div>
</nav>
