import {Component, OnDestroy, OnInit} from '@angular/core';
import {RatingService, Broadcaster, WalletService} from 'src/app/shared/services';
import {ActionModel, RatingModel, WalletModel} from '../../../shared/models';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-wallet-ratings-confirm',
  templateUrl: './confirm.component.html'
})
export class WalletRatingsConfirmComponent implements OnDestroy {
  wallet: WalletModel;
  action: ActionModel;
  rating: RatingModel;

  loaderSubscription$: Subscription;

  // UI Flag
  isLoading = true;
  isSubmitted = false;

  /**
   * @param route
   * @param broadcaster
   * @param walletService
   * @param router
   * @param ratingService
   */
  constructor(
    private route: ActivatedRoute,
    private broadcaster: Broadcaster,
    private walletService: WalletService,
    private router: Router,
    // public
    public ratingService: RatingService,
  ) {
    this.loaderSubscription$ = this.route.params.subscribe(async params => {
      this.loadItem(+params.walletId, +params.ratingId);
    });
  }

  /**
   * @param walletId
   */
  async loadItem(walletId: number, ratingId: number): Promise<void> {
    this.isLoading = true;

    // Load Wallet
    this.wallet = new WalletModel(await this.walletService.loadWallet(walletId));

    // Load current Rating
    if (this.ratingService.getRating() === undefined) {
      this.router.navigate(['/wallets/' + this.wallet.id + '/ratings/new']);
    } else {
      this.rating = this.ratingService.getRating();
      this.action = this.ratingService.getAction();
    }

    this.broadcaster.broadcast('walletDura', {});

    this.isLoading = false;
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.ratingService.resetRating();
  }
}
