<ng-container *ngIf="!isLoading">
  <div class="row">
    <div class="col">
      <!-- header -->
      <div class="card mb-3">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <h4 class="font-weight-bold mb-0">Actions</h4>
            <div class="ml-auto">
              <button
                [routerLink]="'/wallets/' + wallet?.id + '/actions/0/edit'"
                class="btn btn-sm btn-taamana"
              >
                + action
              </button>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <label class="mb-0">Filter</label>
            <ngx-select
              [formControl]="statusFilter"
              [items]="statusOptions"
              optionTextField="name"
              [allowClear]="true"
              placeholder="status"
              optionValueField="id"
              class="wallet--filter small ml-2"
              size="small"
            >
            </ngx-select>
          </div>
        </div>
      </div>

      <!-- content -->
      <div class="card mb-3">
        <div class="list-group list-group-flush">
          <div *ngIf="actions.length === 0" class="list-group-item">
            <h6 class="card-title">No actions found</h6>
            <div class="mdtext">
              Do you want to
              <a
                [routerLink]="'/wallets/' + wallet?.id + '/actions/0/edit'"
                class="taamana"
              >
                create one
              </a>
              ?
            </div>
          </div>

          <div
            *ngFor="let action of actions"
            [routerLink]="
              action.status === 3
                ? '/wallets/' + wallet.id + '/actions/' + action.id
                : '/wallets/' + wallet.id + '/actions/' + action.id + '/edit'
            "
            class="list-group-item action"
          >
            <app-action-header
              [showScore]="true"
              [action]="action"
              [size]="'md'"
              [showStatus]="true"
            >
            </app-action-header>

            <div *ngIf="action.description !== ''" class="mdtext mt-2">
              <ng-container *ngIf="action.description.length > 100"
                >{{ action.description | slice: 0:100 }} ...</ng-container
              >
              <ng-container *ngIf="action.description.length <= 100">{{
                action.description
              }}</ng-container>
            </div>

            <div
              *ngIf="action.description === ''"
              class="small mt-2 text-muted"
            >
              -- no description provided --
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isLoading">
  <app-loader></app-loader>
</ng-container>
