import {Component, OnDestroy} from '@angular/core';
import {AuthService, CatalogService, QueryService, WalletService, RatingService} from 'src/app/shared/services';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ActionModel, WalletModel, RatingModel} from '../../../shared/models';

@Component({
  selector: 'app-wallet-ratings-summary',
  templateUrl: './summary.component.html'
})
export class WalletRatingsSummaryComponent implements OnDestroy {
  wallet: WalletModel;
  action: ActionModel;

  rating: RatingModel;
  country: { name: string; code: string; };

  loaderSubscription$: Subscription;

  // UI Flag
  isLoading = true;
  isSubmitted = false;

  /**
   * @param authService
   * @param route
   * @param queryService
   * @param translateService
   * @param router
   * @param catalogService
   * @param walletService
   * @param ratingService
   */
  constructor(
    private route: ActivatedRoute,
    private queryService: QueryService,
    private translateService: TranslateService,
    private router: Router,
    private catalogService: CatalogService,
    private walletService: WalletService,
    // public
    public ratingService: RatingService,
    public authService: AuthService
  ) {
    this.loaderSubscription$ = this.route.params.subscribe(async params => {
      this.loadItem(+params.id);
    });
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.loaderSubscription$.unsubscribe();
  }

  /**
   * @param walletId
   */
  async loadItem(walletId: number): Promise<void> {
    this.isLoading = true;

    // Load Wallet
    this.wallet = new WalletModel(await this.walletService.loadWallet(walletId));

    // Load current Rating
    if (this.ratingService.getRating() === undefined) {
      this.router.navigate(['/wallets/' + this.wallet.id + '/ratings/new']);
    }

    this.action = this.ratingService.getAction();

    this.isLoading = false;
  }

  save(): boolean | void {
    this.isSubmitted = true;
    this.queryService.q('POST', '/sec/ratings', this.ratingService.getRating()).subscribe(ret => {
      this.isSubmitted = false;
      this.ratingService.setRating(ret);
      this.router.navigate(['/wallets/' + this.wallet.id + '/ratings/' + ret.id + '/confirm']);
    }, err => {
      this.isSubmitted = false;
      console.log('err: ', err);
    });
  }
}
