import {Component} from '@angular/core';

@Component({
  selector: 'app-support-thanks',
  templateUrl: './thanks.component.html'
})
export class ActionSupportThanksComponent {

  // Ui
  isLoading = true;

  constructor(
  ) {
  }
}
