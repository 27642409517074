import {Component, Input} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AuthService, CatalogService, QueryService, WalletService} from 'src/app/shared/services';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ActionModel, WalletModel} from '../../../../shared/models';

@Component({
  selector: 'app-wallet-actions-submit',
  templateUrl: './submit.component.html'
})
export class WalletActionsSubmitComponent {
  wallet: WalletModel;
  action: ActionModel;

  // form instance
  loaderSubscription$: Subscription;

  // UI Flag
  isLoading = true;
  isSubmitted = false;

  /**
   * @param authService
   * @param route
   * @param queryService
   * @param translateService
   * @param formBuilder
   * @param router
   * @param catalogService
   * @param walletService
   */
  constructor(
    private route: ActivatedRoute,
    private queryService: QueryService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private router: Router,
    private catalogService: CatalogService,
    private walletService: WalletService,
    // public
    public authService: AuthService
  ) {
    this.loaderSubscription$ = this.route.params.subscribe(async params => {
      this.loadItem(+params.id, +params.action);
    });
  }

  /**
   * @param walletId
   * @param actionId
   */
  async loadItem(walletId: number, actionId: number): Promise<void> {
    this.isLoading = true;

    // Load Wallet
    this.wallet = new WalletModel(await this.walletService.loadWallet(walletId));
    this.action = new ActionModel(await this.walletService.loadSecuredAction(walletId, actionId));

    this.isLoading = false;
  }

  /**
   */
  save(): boolean | void {
    this.isSubmitted = true;

    this.queryService.q('POST', '/sec/actions/' + this.action.id + '/submit', {}).subscribe(
      ret => {
        this.isSubmitted = false;
        this.router.navigate(['/wallets/' + this.wallet.id + '/actions/' + this.action.id + '/confirm']);
      }, err => {
        console.log('err: ', err);
        this.isSubmitted = false;
      });
  }
}
