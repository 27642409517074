<app-header></app-header>

<div class="container-fluid">
  <div class="row mt-2">

    <div class="col-md-4 col-lg-3">
      <app-dashboard-menu></app-dashboard-menu>
    </div>

    <div class="col-md-8 col-lg-9">
      <router-outlet></router-outlet>
    </div>

  </div>
</div>
