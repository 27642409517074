<ng-container *ngIf="!isLoading">

  <div class="row">
    <div class="col">

      <!-- header -->
      <div class="card mb-3">
        <div class="card-body border-bottom">
          <div class="d-flex align-items-center">
            <div>
              <a [routerLink]="'/wallets/' + wallet.id + '/actions/' + action?.id">
                <i class="la la-arrow-left mr-2"></i>
              </a>
            </div>
            <h4 class="font-weight-bold mb-0">
              Support this action !
            </h4>
          </div>
        </div>

        <!-- content -->
        <div class="card-body">

          <app-action-header
            [wallet]="wallet"
            [action]="action"
            [showScore]="true"
            [showStars]="false"
            [showStatus]="false"
            [showWalletBackLink]="false"
            [showSupportButton]="false">
          </app-action-header>

          <div *ngIf="action?.remaining <= 0" class="alert mt-4">
            <h4 class="alert-heading">Sold out !</h4>
            <p>
              All the shares have been bought ! But you can still support this community by buying shares from an
              another action.
            </p>
          </div>

          <div *ngIf="action?.remaining > 0 && isConnected" class="row mt-4 mb-2">

            <div class="col-12 mb-4">
              <h6 class="font-weight-bold">Please rate this action</h6>
              <app-action-stars-rating (rateEvent)="setRating($event)"></app-action-stars-rating>
            </div>

            <div class="col-12 mb-4">
              <h6 class="font-weight-bold">How much support do you want to provide ?</h6>
              <nouislider [min]="1" [max]="action?.remaining" [step]="1" [formControl]="sharesNumber"
                          [class]="'mt-0'"></nouislider>
            </div>

            <div class="col-12 mb-4">
              <div class="d-flex align-items-center">
                <div class="w-33 text-center">
                  <div class="title xs">Number of shares</div>
                  <h3 class="font-weight-bold">{{ sharesNumber.value }}</h3>
                </div>
                <div class="w-33 text-center">
                  <div class="title xs">Price per share</div>
                  <h3 class="font-weight-bold">D {{action?.unitPrice}}</h3>
                </div>
                <div class="w-33 text-center">
                  <div class="title xs">Total support</div>
                  <h3
                    [class.text-warning]="walletService.getCurrentWallet().balance < share?.amount"
                    [class.text-success]="walletService.getCurrentWallet().balance >= share?.amount"
                    class="font-weight-bold">D {{ share.amount }}</h3>
                </div>
              </div>
            </div>

            <div class="col-12 mb-4">
              <h6 class="font-weight-bold">Leave a message</h6>
              <textarea class="form-control" type="text" [formControl]="sharesMessage"></textarea>
            </div>

            <div *ngIf="walletService.getCurrentWallet().balance < share?.amount" class="col-12">
              <div class="alert alert-warning mb-0">
                <h4 class="alert-heading">Insufficient funds !</h4>
                Your DURA balance si too low for that support, you can <a
                [routerLink]="'/public/wallets/' + walletService.getCurrentWallet().id + '/transactions'">click here</a>
                to make a deposit.
              </div>
            </div>

            <div *ngIf="share.qty <= action.remaining && walletService.getCurrentWallet().balance >= share?.amount"
                 class="col-12 text-center">
              <button (click)="support()" class="btn btn-taamana">
                Support now !
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isLoading">
  <app-loader></app-loader>
</ng-container>

