<ng-container>
  <div class="row">
    <div class="col">

      <!-- header -->
      <div class="card mb-3">
        <div class="card-body border-bottom">
          <div class="d-flex align-items-center">
            <h4 class="font-weight-bold mb-0">
              {{ 'adddura.header' | translate }}
            </h4>
          </div>
        </div>

        <!-- content -->
        <div  class="card card-body">
          <div class="row mt-4 mb-2">
            <div class="col-12 mb-4">
              <h6 class="font-weight-bold">Deposit amount</h6>
              <nouislider [min]="1" [max]="250" [step]="1" [formControl]="duraAmount"
                          [class]="'mt-0'"></nouislider>
            </div>

            <div class="col-12 mb-4">
              <div class="d-flex align-items-center">
                <div class="w-33 text-center">
                  <div class="title xs">Amount</div>
                  <h3 class="font-weight-bold">{{ duraAmount.value }}</h3>
                </div>
              </div>
            </div>

            <div class="col-12 text-center">
              <button type="button" (click)="checkout()" class="btn btn-taamana">
                Deposit
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</ng-container>


