<ng-container *ngIf="!isLoading">
  <div class="row">
    <div class="col-12">
      <!-- MAIN POOL -->
      <div class="card">
        <img src="assets/lanscape.png" class="card-img-top profile" alt="..." />

        <div class="card-body">
          <app-wallet-header
            [wallet]="wallet"
            [showShareButtons]="true"
            [size]="'md'"
            [showBoldName]="true"
            [showScore]="true"
          ></app-wallet-header>

          <div class="d-flex mt-2">
            <div class="p-1 px-2 rounded bg-light small mr-2">
              <i *ngIf="wallet.type === 1" class="la la-user la-lg mr-1"></i>
              <i *ngIf="wallet.type === 2" class="la la-users la-lg mr-1"></i>
              <span>{{ "profile.type." + wallet.type | translate }}</span>
            </div>
            <div class="p-1 px-2 rounded bg-light small">
              <i class="la la-globe la-lg mr-1"></i>
              <span>{{ country?.name }}</span>
            </div>
          </div>
          <div class="mdtext mt-2">{{ wallet.description }}</div>
        </div>
      </div>

      <!-- ACTIONS -->
      <div *ngIf="actions?.length > 0" class="row mt-3 mb-3">
        <div *ngFor="let action of actions" class="col-12 mb-2">
          <app-action-card
            [action]="action"
            [wallet]="wallet"
            [isNew]="notificationService.isActionNew(wallet.id, action.id)"
          ></app-action-card>
        </div>
      </div>

      <div *ngIf="actions?.length === 0" class="row mt-3 mb-2">
        <div class="col-6">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title font-weight-bold">Earn</h5>

              <h6 class="font-weight-bold">Validate actions</h6>
              <p class="card-text">
                You can earn DURA by helping the community to validate quality
                actions by watching short sustainable action videos and evaluate
                the quality of the actions and earn money for that !
              </p>
              <button
                [routerLink]="'/wallets/' + wallet.id + '/ratings'"
                class="btn btn-sm btn-taamana"
              >
                Validate now
                <i class="la la-arrow-right"></i>
              </button>

              <hr />

              <h6 class="font-weight-bold">Submit actions</h6>
              <p class="card-text">
                Earn DURA by submitting short videos of sustainable actions you
                did, have them validate and get financial support from the
                taamana community !
              </p>
              <button
                [routerLink]="'/wallets/' + wallet.id + '/actions'"
                class="btn btn-sm btn-taamana"
              >
                Submit an action
                <i class="la la-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title font-weight-bold">Support</h5>

              <h6 class="font-weight-bold">Support actions</h6>
              <p class="card-text">
                Find quality actions that you love in the marketplace and
                support the producers by buying shares in their actions
              </p>
              <a
                href="javascript:alert('Coming soon !')"
                class="btn btn-sm btn-taamana"
              >
                Deposit DURA
                <i class="la la-arrow-right"></i>
              </a>

              <hr />

              <h6 class="font-weight-bold">Invite your friends</h6>
              <p class="card-text">
                Support communities by spreading the word and growing the
                network together
              </p>
              <a
                href="javascript:alert('Coming soon !')"
                class="btn btn-sm btn-taamana"
              >
                Invite friends now !
                <i class="la la-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<app-loader *ngIf="isLoading"></app-loader>
