// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://taamana-api.radicalloop.com',
  // apiUrl: 'http://taamana-api.test',

  stripePublicKey: 'pk_test_8X8ggccI8KcMhZgxnIZhnos5',
  stripePriceKey: 'price_1LNvEmARxPTR1wnXSwPJLv4E',

  googleAnalyticsKey: 'G-DNGKTL64X2',
  googleMapsKey: 'AIzaSyAAm_O46t4pgP7sAH33qJt7XmuD_IFsIT4'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
