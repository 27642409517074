export class WalletModel {
  id: number;
  name: string;
  score: number;
  type: number;
  color: string;
  description: string;
  country: string;

  balance: number;
  produced: number;
  supported: number;
  earned: number;
  spend: number;
  ratio: number;

  followed: any = [];
  tokens: any = [];

  constructor(data) {
    this.id = data.id ? data.id : 0;
    this.name = data.name ? data.name : '';
    this.score = data.score ? data.score : 0;
    this.type = data.type ? data.type : null;
    this.color = data.color ? data.color : null;
    this.description = data.description ? data.description : '';
    this.country = data.country ? data.country : '';

    this.balance = data.balance ? data.balance : 0;
    this.produced = data.produced ? data.produced : 0;
    this.supported = data.supported ? data.supported : 0;
    this.earned = data.earned ? data.earned : 0;
    this.spend = data.spend ? data.spend : 0;
    this.ratio = data.ratio ? data.ratio : 0;

    this.followed = data.followed ?? [];
    this.tokens = data.tokens ?? [];
  }
}
