import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DataPayloadModel, WalletModel} from '../../../shared/models';
import {Subscription} from 'rxjs';
import {AuthService, Broadcaster, NotificationsService, WalletService} from '../../../shared/services';
import {Router} from '@angular/router';

@Component({
  selector: 'app-wallet-menu',
  templateUrl: './menu.component.html'
})
export class WalletMenuComponent implements OnInit, OnDestroy {

  wallets: WalletModel[] = [];
  currentWallet: WalletModel = undefined;
  network: any = [];
  notifications: any;

  balanceBroadcast$: Subscription;
  networkBroadcast$: Subscription;

  isLoading = true;
  isNetworkLoading = true;
  isConnected: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private broadcaster: Broadcaster,
    // Used in template
    public walletService: WalletService,
    public notificationService: NotificationsService,
  ) {
    this.balanceBroadcast$ = this.broadcaster.on('walletDura')
      .subscribe(async (evt: string) => {
        await this.loadItems(true, true);
      });
    this.networkBroadcast$ = this.broadcaster.on('walletNetwork')
      .subscribe(async (evt: string) => {
        const tmp: DataPayloadModel = await this.walletService.loadNetwork(this.currentWallet.id);
        this.network = tmp.data;
      });
    this.isConnected = this.authService.isConnected();
  }

  async ngOnInit(): Promise<void> {
    await this.loadItems();
  }

  async loadItems(silent = false, forceReload = false): Promise<void> {
    this.isLoading = !silent;
    this.wallets = await this.walletService.loadUsersWallets(true);
    await this.changeWallet(this.walletService.getCurrentWallet(), false);
    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.balanceBroadcast$.unsubscribe();
    this.networkBroadcast$.unsubscribe();
  }

  logout(): void {
    this.walletService.resetWallets();
    this.authService.logout();
  }

  async changeWallet(wallet, navigate = true): Promise<void> {
    this.isNetworkLoading = true;

    this.currentWallet = wallet;
    this.walletService.setCurrentWallet(wallet);

    this.network = await this.walletService.loadNetwork(wallet.id);

    await this.notificationService.initNotifications(this.authService.getId(), wallet.id);

    this.isNetworkLoading = false;

    if (navigate === true) {
      this.router.navigate(['/wallets/' + wallet.id]);
    }
  }
}
