<ng-container *ngIf="!isLoading">

  <div class="row">
    <div class="col">

      <!-- header -->
      <div class="card mb-3">
        <div class="card-body">
          <app-action-header
            [showScore]="true"
            [action]="action"
            [size]="'md'"
            [showStatus]="true">
          </app-action-header>
        </div>
      </div>

      <!-- content -->
      <div class="card mb-3">
        <div class="card-body">

          <h6 class="card-title">
            Submission confirmed
          </h6>

          <p class="card-text">
            You action will no be reviewed by the community. Keep an eye on your action, it will be updated as soon as possible !
          </p>

          <div class="d-flex justify-content-around align-items-center">
            <div class="mb-2">
              <button [routerLink]="'/wallets/' + walletService.getCurrentWallet().id + '/actions'"
                      class="btn btn-sm wide btn-taamana w-100">
                <i class="la la-arrow-left la-lg"></i>
                back to actions list
              </button>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</ng-container>

<app-loader *ngIf="isLoading"></app-loader>
