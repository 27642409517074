import {Component, OnInit} from '@angular/core';
import {CatalogService, QueryService, RegisterService} from '../../../shared/services';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-reg-profile',
  templateUrl: './step.component.html',
})
export class RegStepProfileComponent implements OnInit {
  countries: any;

  constructor(
    private catalogService: CatalogService,
    private queryService: QueryService,
    private router: Router,
    private toastr: ToastrService,
    public registerService: RegisterService
  ) {
  }

  async ngOnInit(): Promise<void> {
    if (!this.registerService.user.email) {
      this.router.navigate(['/register/perso']);
    } else {
      this.countries = await this.catalogService.getAllCountries();
    }
  }

  isDisabled(): boolean {
    return !this.registerService.wallet.name ||
      !this.registerService.wallet.description ||
      !this.registerService.wallet.type;
  }

  register(): void {
    const $q = this.registerService.register();
    $q.subscribe(
      evt => {
        this.router.navigate(['/register/confirm']);
      },
      err => {
        console.log(err);
      }
    );
  }

}
