<div class="custom-file">
  <form [formGroup]="formGroup">
    <input  (change)="handleFileInput($event.target.files)"
            accept="image/png, image/jpeg, image/gif, video/mp4,video/x-m4v,video/*"
            class="custom-file-input" 
            (change)="onFileChanged($event)"
            formControlName="{{ controlName }}" id="file" type="file">
      <label class="custom-file-label" for="file">Choose file...</label>
      <button (click)="post()" class="btn btn-small btn-primary m-2" type="button">
        <i class="las la-file-upload"></i> Upload
      </button>
  </form>
</div>
