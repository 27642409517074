import {Subject, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

interface BroadcastEvent {
  key: any;
  data?: any;
}

//  -- Broadcast Event with ID eventName
//  this.broadcaster.broadcast('eventName', {..data..});
// -- Subscribe to Broadcast ID eventName
//  this.broadcastSubscription$ = this.broadcaster.on('eventName')
//    .subscribe((data: string) => {
//      -- Do something here with 'data'
//  });

@Injectable()
export class Broadcaster {
  private eventBus: Subject<BroadcastEvent>;

  constructor() {
    this.eventBus = new Subject<BroadcastEvent>();
  }

  broadcast(key: any, data?: any): void {
    this.eventBus.next({key, data});
  }

  on<T>(key: any): Observable<T> {
    return this.eventBus.asObservable().pipe(
      filter(event => event.key === key),
      map(event => event.data)
    );
  }
}
