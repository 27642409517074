<ng-container *ngIf="!isLoading">
  <div class="row mb-3">
    <div class="col-12">
      <!-- action -->
      <div class="card mb-3">
        <img src="assets/lanscape.png" class="card-img-top action" alt="..." />

        <div class="card-body">
          <app-action-header
            [wallet]="wallet"
            [action]="action"
            [showStatus]="false"
            [showWalletBackLink]="true"
            [showStars]="true"
            [showScore]="true"
            [showSupportButton]="true"
            [showShareButtons]="true"
          >
          </app-action-header>

          <div class="my-3 text-center">
            <app-file-display
              [file]="action.files[0]"
              [cssClass]="'file active max'"
            ></app-file-display>
          </div>

          <div class="row mb-2">
            <div class="col-12">
              <span class="small text-muted">Producer</span>
            </div>
            <div class="col-12 d-flex flex-row">
              <app-wallet-header
                [wallet]="action?.producer"
                [size]="'xs'"
                [showName]="true"
                [showScore]="false"
                [showLink]="true"
              ></app-wallet-header>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-12">
              <span class="small text-muted">Informations</span>
            </div>
            <div class="col-12 d-flex flex-wrap">
              <div class="p-1 px-2 rounded bg-light small mr-2">
                <i class="la la-globe la-lg mr-1"></i>
                <span>{{ country?.name }}</span>
              </div>
              <div
                *ngFor="let theme of action?.themes"
                class="p-1 px-2 rounded bg-light small mr-2"
              >
                <i class="la la-lg {{ theme.icon }} mr-1"></i>
                <span>{{ theme.name }}</span>
              </div>
              <div class="p-1 px-2 rounded bg-light small mr-2">
                <i class="la la-dollar la-lg mr-1"></i>
                <span>{{ action?.valueLevel }} budget</span>
              </div>
              <div
                *ngIf="action?.remaining > 0"
                class="p-1 px-2 rounded bg-light small mr-2"
              >
                <i class="las la-money-check la-lg mr-1"></i>
                <span>{{ action?.remaining }} shares</span>
              </div>
              <div
                *ngIf="action?.remaining === 0"
                class="p-1 px-2 rounded bg-warning small mr-2"
              >
                <i class="las la-money-check la-lg mr-1"></i>
                <span>sold out !</span>
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-12">
              <span class="small text-muted">Location</span>
            </div>
            <div class="col-12">
              <agm-map
                style="height: 250px"
                [latitude]="action?.latitude"
                [longitude]="action?.longitude"
                [zoom]="6"
                [disableDefaultUI]="false"
                [zoomControl]="true"
                [mapTypeControl]="true"
                [fullscreenControl]="true"
              >
                <agm-marker
                  [latitude]="action?.latitude"
                  [longitude]="action?.longitude"
                  [markerDraggable]="false"
                ></agm-marker>
              </agm-map>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-12">
              <span class="small text-muted">Objective</span>
            </div>
            <div class="col-12">
              <span *ngIf="action?.objective !== ''" class="mdtext">{{
                action?.objective
              }}</span>
              <span *ngIf="!action?.objective">-</span>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-12">
              <span class="small text-muted">Description</span>
            </div>
            <div class="col-12">
              <span *ngIf="action?.description !== ''" class="mdtext">{{
                action?.description
              }}</span>
              <span *ngIf="!action?.description">-</span>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-12">
              <span class="small text-muted">Budget</span>
            </div>
            <div class="col-12">
              <span *ngIf="action?.budget !== ''" class="mdtext">{{
                action?.budget
              }}</span>
              <span *ngIf="!action?.budget">-</span>
            </div>
          </div>
        </div>
      </div>

      <!-- shares -->
      <div *ngIf="shares.length > 0" class="card mb-3">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <h6 class="font-weight-bold mb-0">Supports</h6>
          </div>
        </div>
        <div class="list-group list-group-flush">
          <div *ngFor="let share of shares" class="list-group-item">
            <div class="d-flex">
              <app-wallet-header
                [size]="'xs'"
                [wallet]="share.owner"
                [showLink]="true"
                [showFollow]="false"
              >
              </app-wallet-header>
            </div>
            <div class="d-flex flex-row align-items-center mt-2">
              <div>
                <app-action-stars
                  [stars]="share.stars"
                  [size]="'xs'"
                  [showValue]="false"
                ></app-action-stars>
              </div>
              <div class="p-1 px-2 rounded bg-light small ml-2">
                <i class="las la-money-check la-lg mr-1"></i>
                <span> {{ share.qty }} shares</span>
              </div>
            </div>
            <div class="mt-2 mdtext">
              {{ share.message }}
            </div>
          </div>
        </div>
      </div>

      <!-- ratings -->
      <div class="card mb-3">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <h6 class="font-weight-bold mb-0">Validations</h6>
          </div>
        </div>
        <div class="list-group list-group-flush">
          <div *ngFor="let rating of ratings" class="list-group-item">
            <div class="d-flex">
              <app-wallet-header
                [size]="'xs'"
                [wallet]="rating.wallet"
                [showLink]="true"
                [showFollow]="false"
              >
              </app-wallet-header>
            </div>
            <div class="mt-2 mdtext">
              {{ rating.comment }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isLoading">
  <app-loader></app-loader>
</ng-container>
