import { Component } from '@angular/core';
import { AuthService, Broadcaster } from '../../shared/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './menu.component.html'
})
export class DashboardMenuComponent {

  constructor(
    private authService: AuthService,
    private router: Router,
    private broadcaster: Broadcaster,
  ) {
  }
}
