import {Component, Input} from '@angular/core';
import {WalletModel} from '../../models';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html'
})
export class AvatarComponent  {
  @Input() wallet: WalletModel;
  @Input() size: string; // ('xs'|'sm'|'md'|'lg'|'xl');
  @Input() showName = false;
  @Input() notifications: number;
  @Input() showBoldName = false;

  constructor() {}
}
