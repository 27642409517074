import { Component, Input } from '@angular/core';
import { AuthService } from '../../services';
import { ShareModel } from '../../models';

@Component({
  selector: 'app-share-card',
  templateUrl: './share-card.component.html'
})
export class ShareCardComponent {
  @Input() share: ShareModel;

  country: { name: string; code: string; };

  isAdmin = false;
  isConnected = false;

  constructor(
    private authService: AuthService,
  ) {
    this.isAdmin = this.authService.isAdmin();
    this.isConnected = this.authService.isConnected();
  }
}
