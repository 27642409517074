<ng-container *ngIf="!isLoading">

  <div class="row mb-2">
    <div class="col">
      <div class="card mb-3">

        <!-- header -->
        <div class="card-body border-bottom">
          <div class="d-flex align-items-center">
            <h4 class="font-weight-bold mb-0">
              Invitation tokens
            </h4>
          </div>
        </div>

        <!-- content -->
        <div class="list-group list-group-flush">
          <div *ngFor="let token of wallet.tokens" class="list-group-item">
            <div class="d-flex align-items-center">
              <h2 (click)="copyToClipboard(token.token)" id="{{ token.token }}">
                {{ token.token }}
                <span class="md">
                  <i class="las la-copy text-muted"></i>
                </span>
              </h2>

            </div>
            <div class="mdtext text-muted">
              <span class="font-weight-bold">{{ token.uses }}</span> uses left,
              granting <span class="font-weight-bold">D {{ token.dura }}</span>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</ng-container>

<app-loader *ngIf="isLoading"></app-loader>

