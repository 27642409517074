<div [routerLink]="'/wallets/' + wallet?.id" class="card link">
  <div class="card-body">
    <div class="d-flex flex-row">
      <div>
        <app-avatar [size]="'sm'" [wallet]=" wallet"></app-avatar>
        <div class="d-flex justify-content-center">
          <span *ngIf="index!==undefined" class="font-weight-bold pt-3 title lg">#{{index+1}}</span>
        </div>
      </div>
      <div class="ml-2">
        <div>
          <h5 class="font-weight-bold">{{wallet?.name}}</h5>
        </div>
        <p class="text-muted small">{{ wallet?.description }}</p>
        <div class="d-flex flex-row">
          <div class="d-flex p-1 mb-1 rounded bg-light mr-2 small">
            <span class="font-weight-bold mr-1">{{ wallet?.produced }}</span>
            produced
          </div>
          <div class="d-flex p-1 mb-1 rounded bg-light mr-2 small">
            <span class="font-weight-bold mr-1">{{ wallet?.supported }}</span>
            supported
          </div>
          <div class="d-flex p-1 mb-1 rounded earned mr-2 small">
            <span class="font-weight-bold mr-1">{{ wallet?.earned }}</span>
            earned
          </div>
          <div class="d-flex p-1 mb-1 rounded spend mr-2 small">
            <span class="font-weight-bold mr-1">{{ wallet?.spend }}</span>
            spend
          </div>
        </div>
      </div>
      <div class="ml-auto">
        <div>
          <div class="score py-1 px-2 text-right">
            <i class="las la-star score"></i> {{wallet?.score}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
