<div class="container">
  <div class="form-group">
    <label>{{ "googleMaps.address" | translate }}</label>
    <input
      class="form-control"
      (keydown.enter)="$event.preventDefault()"
      placeholder="{{ 'googleMaps.placeholder' | translate }}"
      autocapitalize="off"
      spellcheck="off"
      type="text"
      #search
    />
  </div>

  <agm-map
    style="height: 500px"
    [latitude]="latitude"
    [longitude]="longitude"
    [zoom]="zoom"
    [disableDefaultUI]="false"
    [zoomControl]="true"
    [mapTypeControl]="true"
    [fullscreenControl]="true"
    (mapClick)="mapClicked($event)">
    <agm-marker
      [latitude]="latitude"
      [longitude]="longitude"
      [markerDraggable]="false"
    ></agm-marker>
  </agm-map>

  <div class="mt-2">
    <h5>{{ "googleMaps.address-header" | translate }}: {{ address }}</h5>
    <div>{{ "googleMaps.latitude" | translate }}: {{ latitude }}</div>
    <div>{{ "googleMaps.longitude" | translate }}: {{ longitude }}</div>
  </div>
</div>
