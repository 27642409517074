import {Component, Input, OnInit} from '@angular/core';
import {AuthService, QueryService, WalletService} from '../../../shared/services';
import {ActivatedRoute} from '@angular/router';
import {WalletModel} from '../../../shared/models';

@Component({
  selector: 'app-pub-wallets-similar',
  templateUrl: './similar.component.html'
})
export class WalletsSimilarComponent implements OnInit {
  @Input() wallet: WalletModel;

  isAdmin = false;
  isConnected = false;

  wallets: WalletModel[];

  isLoading = true;

  constructor(
    public authService: AuthService,
    public walletService: WalletService,
    public route: ActivatedRoute,
    public queryService: QueryService
  ) {
    this.isAdmin = this.authService.isAdmin();
    this.isConnected = this.authService.isConnected();
  }

  ngOnInit(): void {
    this.loadItem(this.wallet?.id);
  }

  async loadItem(itemId: number): Promise<void>
  {
    this.isLoading = true;
    this.wallets = await this.walletService.loadSimilarWallets(itemId);
    this.isLoading = false;
  }
}
