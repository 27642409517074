import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {AuthService, QueryService, WalletService} from '../../shared/services';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  showSpinner = false;
  token: any;
  error: any;

  constructor(
    private formBuilder: FormBuilder,
    private queryService: QueryService,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private walletService: WalletService
  ) {
  }

  ngOnInit(): void {
    this.form = this.buildForm();
  }

  buildForm(): FormGroup {
    return this.formBuilder.group({
      email: [this.authService.getEmail(), Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmit(): void {
    this.showSpinner = true;
    this.queryService.q(
      'POST',
      environment.apiUrl + '/login_check',
      this.form.value
    ).subscribe(
      // Callback on data return
      async (el: { token: string; }) => {
        this.walletService.resetWallets();
        this.showSpinner = false;
        this.authService.setToken(el.token);
        this.authService.setEmail(this.form.value.email);
        const wallets = await this.walletService.loadUsersWallets();
        if (wallets.length > 0) {
          this.router.navigate(['/wallets/' + wallets[0].id]);
        } else {
          this.toastr.error('login.error');
        }
      },
      // Error
      err => {
        this.showSpinner = false;
        this.toastr.error('login.error');
        this.error = err;
      },
      () => {
        this.showSpinner = false;
      }
    );
  }
}
