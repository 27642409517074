<app-page-title [title]="'Top Profiles'">
  <ng-container *ngIf="!isFilterLoading">
    <!-- FILTERS -->
    <div class="d-flex">
        <ngx-select
          [formControl]="countryFilter" [items]="countryOptions"
          optionTextField="name"
          [allowClear]="true"
          placeholder="countries"
          optionValueField="code"
          class="wallet--filter small mr-2"
          size="small">
          <ng-template ngx-select-option ngx-select-option-selected let-option let-text="text">
            <span [innerHtml]="text" class="small"></span>
          </ng-template>
        </ngx-select>
        <ngx-select
          [formControl]="themeFilter" [items]="themeOptions"
          optionTextField="name"
          [allowClear]="true"
          placeholder="themes"
          class="wallet--filter small"
          optionValueField="id"
          size="small">
          <ng-template ngx-select-option ngx-select-option-selected let-option let-text="text">
            <span [innerHtml]="text" class="small"></span>
          </ng-template>
        </ngx-select>
    </div>
  </ng-container>
</app-page-title>

<ng-container *ngIf="!isLoading">
  <div *ngFor="let wallet of wallets | slice:0:5; let idx=index;"  class="row mb-3">
    <div class="col mb-1">
      <app-pub-wallet-card [wallet]="wallet" [index]="idx"></app-pub-wallet-card>
    </div>
  </div>
  <div *ngIf="wallets.length === 0" class="alert alert-info">
    No results found
  </div>
</ng-container>

<ng-container *ngIf="isLoading">
  <app-loader></app-loader>
</ng-container>
