import { Component, OnInit } from '@angular/core';
import { MetricService } from '../../shared/services';

@Component({
  selector: 'app-dashboard-top-metrics-sold-shares',
  templateUrl: './metric.component.html'
})
export class DashboardTopMetricsSoldSharesComponent implements OnInit {

  isLoading = true;
  metrics = {};

  constructor(
    private metricService: MetricService,
  ) {
  }

  ngOnInit(): void {
    this.loadMetrics();
  }

  async loadMetrics(payload = {}): Promise<void> {
    this.isLoading = true;

    // Sample function to get metrics
    // this.metrics = await this.metricService.loadMetric(payload);

    const data = await this.metricService.getTopMetricsSoldSharesData();

    this.metrics = {
      sold_shares_percentage: data?.sold_shares_percentage,
      sold_shares_days_average: data?.sold_shares_days_average,
      soldout_actions_percentage: data?.soldout_actions_percentage,
      soldout_actions_days_average: data?.soldout_actions_days_average
    };

    this.isLoading = false;
  }
}
