import { Component } from '@angular/core';

@Component({
  selector: 'app-header-reg-right',
  templateUrl: './header.component.html'
})
export class HeaderRegRightComponent {

  constructor() {
  }

}
