export class UserModel {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  themes: {id: number; name: string; icon: string; }[];
  password: string;
  code: string;

  constructor(data) {
    this.id = data.id ?? 0;
    this.firstName = data.first_name ?? '';
    this.lastName = data.last_name ?? '';
    this.email = data.email ?? '';
    this.country = data.country ?? null;
    this.themes = data.themes ?? [];
    this.password = data.password ?? '';
    this.code = data.code ?? '';
  }
}
