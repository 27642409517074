export * from './list/list.component';
export * from './new/new.component';
export * from './action/action.component';
export * from './efficiency/efficiency.component';
export * from './effectiveness/effectiveness.component';
export * from './quality/quality.component';
export * from './commongoods/commongoods.component';
export * from './videoquality/videoquality.component';
export * from './comment/comment.component';
export * from './summary/summary.component';
export * from './confirm/confirm.component';
export * from './noactions/noactions.component';
