export class NotificationModel {
  id: number;
  source: { id: number; name: string; } | number; // details or ID
  wallet: { id: number; name: string; } | number; // details or ID
  usr: { id: number; name: string; } | number; // details or ID
  stamp: string;
  status: number;
  data: any;
  type: number;

  constructor(data) {
    this.id = data.id ? data.id : 0;
    this.source = data.source ?? {};
    this.wallet = data.wallet ?? {};
    this.usr = data.usr ?? {};

    this.stamp = data.stamp ?? null;
    this.status = data.status ?? 0;
    this.data = data.data ?? {};
    this.type = data.type ?? 0;
  }
}
