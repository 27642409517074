<ng-container *ngIf="!isLoading">

  <div class="row">
    <div class="col">

      <!-- header -->
      <div class="card mb-3">
        <div class="card-body">
          <app-action-header
            [showScore]="true"
            [action]="action"
            [size]="'md'"
            [showStatus]="true">
          </app-action-header>
        </div>
      </div>

      <!-- content -->
      <div class="card mb-3">
        <div class="card-body">

          <h6 class="card-title">
            Submit your action for validation
          </h6>

          <p class="card-text mdtext">
            Once submited, your action will be reviewed by the community and ranked using several criterias.
          </p>

          <p class="card-text mdtext">
            Your action will be pending until final validation; it's status will then be updated and your action
            will be published. You'll be able to share it with your network to collect support
          </p>

          <p class="card-text mdtext">
            In order to reward the validators, you have to pay a
            <span class="font-weight-bold">D {{ action.submissionFee }}</span> fee.
            This fee will be distributed amongst all the members that will validate your action.
          </p>

          <div class="d-flex justify-content-around align-items-center">
            <div>
              <button [routerLink]="'/wallets/' + walletService.getCurrentWallet().id + '/actions/' + action.id + '/edit'"
                      class="btn btn-sm btn-light">
                <i class="la la-arrow-left"></i> back to action
              </button>
            </div>
            <div *ngIf="action.submissionFee > wallet.balance; else saveButton" class="mb-2">
              <button [routerLink]="'/wallets/' + walletService.getCurrentWallet().id + '/transactions'"
                      class="btn btn-sm wide btn-warning w-100">
                Insufficient funds !<br>Click here to make a deposit
              </button>
            </div>
            <ng-template #saveButton>
              <button (click)="save()" [disabled]="isSubmitted"
                      class="btn btn-sm btn-taamana">
                <i *ngIf="!isSubmitted" class="la la-check-square la-lg"></i>
                <i *ngIf="isSubmitted" class="la la-spinner la-spin la-lg"></i>
                Submit
              </button>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-container>

<app-loader *ngIf="isLoading"></app-loader>
