import { Injectable } from '@angular/core';
import { QueryService } from './query.service';
import { WalletService } from './wallet.service';
import { ActionModel, RatingModel, WalletModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class RatingService {

  action: ActionModel;
  rating: RatingModel;

  constructor(
    private queryService: QueryService,
    private walletService: WalletService
  ) {
  }

  /**
   *
   * @param wallet
   * @param userId
   */
  async initRating(wallet: WalletModel, userId: number): Promise<void | boolean> {
    try {
      const action = await this.loadAction(wallet.id);
      this.action = new ActionModel(action);
      this.rating = new RatingModel({
        id: 0,
        action: this.action.id,
        wallet: wallet.id,
        user: userId
      });
      return true;
    } catch (err) {
      return false;
    }
  }

  /**
   * @param walletId
   */
  async loadAction(walletId: number): Promise<any> {
    // Select Action to be validated
    return await this.walletService.loadActionToValidate(walletId);
  }

  /**
   *
   */
  getRating(): RatingModel {
    return this.rating;
  }

  /**
   *
   */
  getAction(): ActionModel {
    return this.action;
  }

  /**
   *
   */
  setRating(rating: RatingModel): void {
    this.rating = rating;
  }

  /**
   *
   */
  resetRating(): void {
    this.rating = undefined;
  }

  calculateScore(): void {
    const total = this.rating.efficiency +
      this.rating.effectiveness +
      this.rating.quality +
      this.rating.commongoods +
      this.rating.videoquality;

    const avg = (total > 0) ? total / 5 : 0;
    this.rating.score = +avg.toFixed(2);
  }
}
