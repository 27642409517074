<div class="content">
  <div class="row">
    <div class="col-12">
      <h2 class="font-weight-bold">Choose topics</h2>
    </div>
    <div class="col-12">
      <div class="notice">
        Please select one or more topics you are interested in so we can recommend you
      </div>
    </div>
    <div class="col-12 mt-3">
      <div class="row">
        <div *ngFor="let theme of themes" class="col-6">
          <div (click)="setTheme(theme)" [class.active]="haveTheme(theme)" class="card theme">
            <div class="card-body d-flex flex-row align-items-center">
              <div class="ico mr-2"><i class="la {{theme.icon}} la-2x"></i></div>
              <div class="name">{{theme.name}}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12 d-flex justify-content-center">
      <button [disabled]="isDisabled()" class="btn wide btn-sm btn-taamana" (click)="submit()">
        Submit
        <i class="la la-arrow-right"></i>
      </button>
    </div>
  </div>

</div>
