import { Injectable, OnInit } from '@angular/core';
import { QueryService } from './query.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  // Themes = SDGs
  themes: any;

  // Countries
  countries: any;

  // Action values scales
  values: any;

  // Action statuses
  statuses: any;

  // Transaction Types statuses
  transactionsTypes: any;

  constructor(
    private queryService: QueryService
  ) {
  }

  /**
   */
  async getAllThemes(): Promise<any[]> {
      return this.queryService.q('GET', '/catalog/themes/all').toPromise();
  }

  /**
   */
  async getThemes(): Promise<any[]> {
    if (!this.themes) {
      this.themes = this.queryService.q('GET', '/catalog/themes').toPromise();
    }
    return this.themes;
  }

  /**
   */
   async getAllCountries(): Promise<any[]> {
    if (!this.countries) {
      this.countries = await this.queryService.q('GET', '/catalog/countries/all').toPromise();
    }
    return this.countries;
  }

  /**
   */
   async getCountries(): Promise<any[]> {
    return await this.queryService.q('GET', '/catalog/countries').toPromise();
  }

  /**
   * @param countryCode
   */
  async getCountry(countryCode: string): Promise<any> {
     if (!this.countries) {
       await this.getAllCountries();
     }
     return _.find(this.countries, {code: countryCode});
  }

  /**
   */
   async getValues(): Promise<any[]> {
    if (!this.values) {
      this.values = await this.queryService.q('GET', '/catalog/values').toPromise();
    }
    return this.values;
  }

  /**
   */
   async getStatuses(): Promise<any[]> {
    if (!this.statuses) {
      this.statuses = await this.queryService.q('GET', '/catalog/statuses').toPromise();
    }
    return this.statuses;
  }

  /**
   */
   async getTransactionsTypes(): Promise<any[]> {
    if (!this.transactionsTypes) {
      this.transactionsTypes = await this.queryService.q('GET', '/catalog/transactions-types').toPromise();
    }
    return this.transactionsTypes;
  }

  /**
   */
   async getNotificationsTypes(): Promise<any[]> {
    if (!this.transactionsTypes) {
      this.transactionsTypes = await this.queryService.q('GET', '/catalog/notifications-types').toPromise();
    }
    return this.transactionsTypes;
  }
}
