export class RatingModel {
  id: number;
  action?: any; // details or ID;
  user?: any; // details or ID;
  wallet?: any; // details or ID;
  stamp: string;
  efficiency: number;
  effectiveness: number;
  quality: number;
  commongoods: number;
  videoquality: number;
  comment: string;
  score: number;
  reward: number;

  constructor(data) {
    this.id = data.id ? data.id : 0;
    this.action = data.action ?? null;
    this.user = data.user ?? null;
    this.wallet = data.wallet ?? null;

    this.stamp = data.stamp ? data.stamp : null;
    this.efficiency = data.efficiency ? data.efficiency : 0;
    this.effectiveness = data.effectiveness ? data.effectiveness : 0;
    this.quality = data.quality ? data.quality : 0;
    this.commongoods = data.commongoods ? data.commongoods : 0;
    this.videoquality = data.videoquality ? data.videoquality : 0;
    this.comment = data.comment ?? '';
    this.score = data.score ?? 0;
    this.reward = data.reward ?? 0;
  }
}


