<form [formGroup]="form" class="content">
  <div class="row">
    <div class="col-12">
      <h2 class="">Login</h2>
    </div>
    <div class="col-12 mt-3">
      <label>Email</label>
      <div>
        <input class="form-control" formControlName="email" id="email"
               placeholder="{{ 'login.fields.email.holder' | translate }}"
               type="text"/>
      </div>
    </div>
    <div class="col-12 mt-3">
      <label>Password</label>
      <div>
        <input class="form-control" formControlName="password" id="password"
               placeholder="{{ 'login.fields.password.holder' | translate }}"
               type="password"/>
      </div>
    </div>
    <div class="col-12 flex-column mt-3">
      <button (click)="onSubmit()" [disabled]="form.invalid || showSpinner" class="btn btn-lg btn-taamana w-100"
              type="submit">
        {{ 'action.login' | translate }}
      </button>
    </div>
  </div>
</form>
