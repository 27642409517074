<ng-container *ngIf="!isLoading">


  <div class="row">
    <div class="col">

      <!-- header -->
      <div class="card mb-3">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <h4 class="font-weight-bold mb-0 mr-2">
              Validation -
            </h4>
            <app-action-header
              [action]="action"
              [showScore]="false"
              [showStars]="false"
              [showStatus]="false"
            ></app-action-header>
          </div>
        </div>
      </div>

      <!-- content -->
      <div class="card mb-3">
        <div class="card-body">
          <h6 class="card-title">
            Summary
          </h6>
        </div>

        <div class="list-group list-group-flush">
          <div class="list-group-item d-flex justify-content-between align-items-center">
            <div class="font-weight-bold">Efficiency</div>
            <div><span class="badge badge-xl badge-light">{{ ratingService.rating.efficiency }}</span></div>
          </div>
          <div class="list-group-item d-flex justify-content-between align-items-center">
            <div class="font-weight-bold">Effectiveness</div>
            <div><span class="badge badge-xl badge-light">{{ ratingService.rating.effectiveness }}</span></div>
          </div>
          <div class="list-group-item d-flex justify-content-between align-items-center">
            <div class="font-weight-bold">Overall Quality</div>
            <div><span class="badge badge-xl badge-light">{{ ratingService.rating.quality }}</span></div>
          </div>
          <div class="list-group-item d-flex justify-content-between align-items-center">
            <div class="font-weight-bold">Common goods</div>
            <div><span class="badge badge-xl badge-light">{{ ratingService.rating.commongoods }}</span></div>
          </div>
          <div class="list-group-item d-flex justify-content-between align-items-center">
            <div class="font-weight-bold">Video Quality</div>
            <div><span class="badge badge-xl badge-light">{{ ratingService.rating.videoquality }}</span></div>
          </div>
          <div class="list-group-item border-bottom">
            <div class="font-weight-bold">Comment</div>
            <p>{{ ratingService.rating.comment }}</p>
          </div>
          <div class="list-group-item d-flex justify-content-between align-items-center">
            <div class="font-weight-bold">Average</div>
            <div><span class="badge badge-xl badge-taamana">{{ ratingService.rating.score }}</span></div>
          </div>
        </div>

        <div class="card-body">
          <div class="d-flex justify-content-around justify-content-between align-items-center">
            <div>
              <a class="btn btn-sm btn-taamana" [routerLink]="'/wallets/' + wallet.id + '/ratings/comment'">
                <i class="la la-arrow-left"></i>
                Comment
              </a>
            </div>
            <div>
              <button class="btn btn-sm btn-taamana" (click)="save()">
                <i class="la la-lg la-save ml-1"></i>
                Submit validation
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</ng-container>


<app-loader *ngIf="isLoading"></app-loader>
