export class TransactionModel {
  id: number;
  wallet: { id: number; name: string; } | number; // details or ID
  amount: number;
  stamp: string;
  description: string;
  type: string;
  data: any;

  constructor(data) {
    this.id = data.id ? data.id : 0;
    this.wallet = data.wallet ?? {};
    this.amount = data.amount ?? 0;
    this.stamp = data.stamp ?? null;
    this.description = data.description ?? null;
    this.type = data.type ?? null;
    this.data = data.data ?? {};
  }
}
