import {Component, OnInit} from '@angular/core';
import {AuthService, Broadcaster, CatalogService, QueryService, WalletService} from 'src/app/shared/services';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {WalletModel} from '../../../shared/models';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-pub-wallet-tokens',
  templateUrl: './tokens.component.html'
})
export class WalletTokensComponent implements OnInit {
  wallet: WalletModel;

  loaderSubscription$: Subscription;

  // UI Flag
  isLoading = true;
  isSubmitted = false;

  /**
   * @param authService
   * @param walletService
   * @param route
   * @param queryService
   * @param translateService
   * @param router
   * @param toastrService
   * @param broadcaster
   */
  constructor(
    public authService: AuthService,
    public walletService: WalletService,
    public route: ActivatedRoute,
    public queryService: QueryService,
    public translateService: TranslateService,
    private router: Router,
    private toastrService: ToastrService,
    private broadcaster: Broadcaster
  ) {
    this.loaderSubscription$ = this.route.params.subscribe(async params => {
      this.loadItem(+params.id);
    });
  }

  async ngOnInit(): Promise<void> {
  }

  /**
   * @param walletId
   */
  async loadItem(walletId: number): Promise<void> {
    this.isLoading = true;
    let tmpWallet = {};
    if (walletId > 0) {
      tmpWallet = await this.walletService.loadWallet(walletId);
    }
    this.wallet = new WalletModel(tmpWallet);

    this.isLoading = false;
  }

  /**
   * @param elId
   */
  copyToClipboard(elId: string): void {
    const el = document.getElementById(elId);
    if (el) {
      navigator.clipboard.writeText(el.innerText);
    }
    this.toastrService.success('Token  ' + el.innerText + ' copied in clipboard !')
  }
}
