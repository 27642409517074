<div class="modal-header d-flex justify-content-between align-items-center">
  <h5 class="modal-title">Withdraw</h5>
  <div class="d-flex ml-auto mr-1">
    <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <div class="form-group col-12">
        <label for="amount">
          Amount
        </label>
        <input class="form-control form-control-sm" formControlName="amount" id="amount" type="number" />
      </div>
    </div>
  </form>
</div>


<div class="modal-footer">
  <div class="form-group">
    <div class="col-12 d-flex justify-content-between">
      <button (click)="cancelFn()" class="btn btn-light mr-2">
        <i class="la la-ban"></i>
        cancel
      </button>

      <button (click)="save()" [disabled]="isSubmitted" class="btn btn-success">
        <i *ngIf="!isSubmitted" class="la la-save"></i>
        <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
        withdraw
      </button>
    </div>
  </div>
</div>