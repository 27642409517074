import {Component, OnDestroy} from '@angular/core';
import {ActionModel, ShareModel, WalletModel} from '../../../../shared/models';
import {Subscription} from 'rxjs';
import {AuthService, QueryService, WalletService, CatalogService, Broadcaster, ShareService} from '../../../../shared/services';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {SupportConfirmModalComponent} from '../confirm/confirm.component';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-support-form',
  templateUrl: './form.component.html'
})
export class ActionSupportFormComponent implements OnDestroy {
  isAdmin = false;
  isConnected = false;
  isComplete = false;

  // Current Action
  action: ActionModel;
  // Wallet referencing this action
  wallet: WalletModel;
  // Current wallet context (user selected)
  walletCurrent: WalletModel;
  // Current Share
  share: ShareModel;

  sharesNumber: FormControl = new FormControl(1);
  sharesMessage: FormControl = new FormControl('');

  values: any = [];
  country: { name: string; code: string; };
  focusedFile: any;

  // Figures
  amount: number;

  loaderSubscription$: Subscription;

  // Ui
  isLoading = true;
  bsModalRef?: BsModalRef;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private queryService: QueryService,
    private catalogService: CatalogService,
    private bsModalService: BsModalService,
    private broadcaster: Broadcaster,
    private toastr: ToastrService,
    private shareService: ShareService,
    private router: Router,
    // used in template
    public walletService: WalletService,
  ) {
    this.loaderSubscription$ = this.route.params.subscribe(async params => {
      this.loadItem(+params.wallet, +params.id);
    });
    this.sharesNumber.valueChanges.subscribe(qty => {
      this.updateFigures(qty);
    });
    this.isAdmin = this.authService.isAdmin();
    this.isConnected = this.authService.isConnected();
    this.walletCurrent = this.walletService.getCurrentWallet();
  }

  ngOnDestroy(): void {
    this.loaderSubscription$.unsubscribe();
  }

  async loadItem(walletId: number, itemId: number): Promise<void> {
    this.isLoading = true;

    // Load data & catalog data
    this.action = await this.walletService.loadAction(walletId, itemId);
    this.wallet = await this.walletService.loadWallet(walletId);
    this.country = await this.catalogService.getCountry(this.action.country);

    // Create base share item
    if (this.authService.isConnected()) {

      this.share = this.shareService.createShare(
        this.action,
        this.walletService.getCurrentWallet(),
        this.wallet
      );

      this.updateFigures(1);
    }

    this.isLoading = false;
  }

  /**
   *
   * @param stars
   */
  setRating(stars: any): void {
    this.share.stars = stars;
  }

  /**
   * @param qty
   */
  updateFigures(qty: number): void {
    // Amount of share
    this.share.qty = qty;

    // Figures
    this.amount = +(this.share.qty * this.action.unitPrice).toFixed(2);

    // Update current Share
    this.share.amount = this.amount;
  }

  support(): void {
    this.share.message = this.sharesMessage.value;
    if (this.share.qty > this.action.remaining) {
      this.toastr.error('Ooops ! There are only ' + this.action.remaining + ' shares of this actions left !');

    } else if (this.share.stars === null) {
      this.toastr.error('Please rate this Action');

    } else if (this.sharesMessage.value === '') {
      this.toastr.error('Please leave a message');

    } else {
      this.bsModalRef = this.bsModalService.show(SupportConfirmModalComponent, {
        initialState: {
          action: this.action,
          wallet: this.wallet,
          share: this.share
        },
        class: 'modal-dialog-centered modal-xl'
      });
      this.bsModalRef.content.callbackFn = () => {
        this.queryService.q('POST', '/sec/actions/' + this.action.id + '/support', this.share).subscribe(
          () => {
            // Broadcast to update header (show balance)
            this.broadcaster.broadcast('walletDura', {});
            // Reload page
            this.router.navigate(['/wallets/' + this.wallet.id + '/actions/' + this.action.id + '/thanks']);
          },
          err => {
            console.log(err);
          }
        );
        this.bsModalRef?.hide();
      };
      this.bsModalRef.content.cancelFn = () => {
        this.bsModalRef?.hide();
      };
    }
  }
}
