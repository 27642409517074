import { Component, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { RegisterService } from '../../../shared/services';

@Component({
  selector: 'app-reg-step',
  templateUrl: './step.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    'html { height: 100%; }',
    'body { height: 100%; }'
  ]
})
export class RegStepComponent {

  $ls: Subscription;
  baseSteps = [
    {
      rank: 1,
      name: 'Member code',
      url: '/register/code',
      status: 1
    },
    {
      rank: 2,
      name: 'Personal details',
      url: '/register/perso',
      status: 1
    },
    {
      rank: 3,
      name: 'Profile info',
      url: '/register/profile',
      status: 1
    },
    {
      rank: 4,
      name: 'Confirm',
      url: '/register/confirm',
      status: 1
    },
  ];
  steps = [];

  constructor(
    public router: Router,
    private registerService: RegisterService
  ) {
    this.registerService.init();
    this.$ls = this.router.events.subscribe(el => {
      if (el instanceof NavigationEnd) {
        this.buildSteps(el.url);
      }
    });
  }

  buildSteps(url: string): void {
    const tmp = _.cloneDeep(this.baseSteps);
    if (url === '/register') {
      url = '/register/code';
    }
    for (const el of tmp) {
      if (el.url === url) {
        el.status = 2;
        break;
      } else {
        el.status = 3;
      }
    }
    this.steps = tmp;
  }
}
