import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-action-stars-rating',
  templateUrl: './action-stars-rating.component.html'
})
export class ActionStarsRatingComponent {
  @Output() rateEvent: EventEmitter<any> = new EventEmitter();

  hoverRating = 0;
  rating = 0;
  stars: any = [1, 2, 3, 4, 5];

  constructor() {
  }

  hover(rating: number): void {
    this.hoverRating = rating;
  }

  rate(rating: number): void {
    this.rating = rating;
    console.log(this.rating);
    this.rateEvent.emit(this.rating);
  }
}
