<nav class="navbar flex-md-nowrap">

  <div class="d-flex justify-content-between align-items-center w-100">

    <div [routerLink]="'/public'" class="d-flex align-items-center cursor-pointer">
      <h2 class="app-name">
        taamana
      </h2>
      <div class="ml-2">
        <img class="avatar sm circle" src="assets/img/logo.png"/>
      </div>
    </div>

    <ng-container *ngIf="!isLoading">

      <ng-container *ngIf="!isConnected">
        <div class="mr-3">
          <button [routerLink]="'/main/login'" class="btn btn-xs btn-taamana">sign in</button>
          <span class="small mx-2">or</span>
          <button [routerLink]="'/register'" class="btn btn-xs btn-taamana">register</button>
        </div>
      </ng-container>

      <ng-container *ngIf="isConnected">
        <div class="d-flex flex-row ml-auto">

          <div class="btn-group btn-light btn-sm" dropdown placement="bottom">
            <div class="dropdown-toggle rounded" dropdownToggle>
              <div class="d-flex align-items-center">
                <div>
                  <i class="la la-user-circle la-lg text-info"></i>
                  <i class="las la-caret-down ml-1"></i>
                </div>
              </div>
            </div>
            <ul *dropdownMenu aria-labelledby="button-basic" class="dropdown-menu dropdown-menu-right"
                role="menu">
              <li role="menuitem" *ngIf="isAdmin">
                <a class="dropdown-item" [routerLink]="'/dashboard'">
                  <i class="la la-dashboard"></i>
                  Dashboard
                </a>
              </li>
              <li role="menuitem">
                <a (click)="logout()" class="dropdown-item" routerLink="null">
                  <i class="las la-sign-out-alt"></i>
                  Sign out
                </a>
              </li>
            </ul>
          </div>
        </div>
      </ng-container>

    </ng-container>

  </div>

</nav>
