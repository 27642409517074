import { Component } from '@angular/core';

@Component({
  selector: 'app-reg-step-5',
  templateUrl: './step.component.html',
})
export class RegStepConfirmComponent {

  constructor(
  ) {
  }
}
