import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { WalletService } from 'src/app/shared/services';

@Component({
  selector: 'app-sec-transactions-withdraw',
  templateUrl: './withdraw.component.html'
})
export class WalletTransactionsWithdrawModalComponent implements OnInit {
  // form instance
  form!: FormGroup;

  // UI Flag
  isLoading = true;
  data = null;
  isSubmitted = false;

  /**
   * @param bsModalRef
   * @param translate
   * @param formBuilder
   * @param walletService
   */
  constructor(
    public bsModalRef: BsModalRef,
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public walletService: WalletService
  ) {
    this.isLoading = true;
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {
    this.form = this.getForm();
  }

  /**
   *
   */
  getForm(): FormGroup {
    return this.formBuilder.group({
      amount: new FormControl(0)
    });
  }

  /**
   * callBack & Cancel
   */
   save(): void {
    this.callbackFn(this.form.value);
  }
  callbackFn = (evt: any) => { };
  cancelFn = () => { };
}
