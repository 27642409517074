<ng-container *ngIf="!isLoading">
  <div class="row">
    <div class="col">
      <div class="card mb-3">
        <div class="card-body">
          <h5 class="font-weight-bold mb-0">Sold Shares</h5>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <div class="card mb-3">
        <div class="card-body">
          <span class="text-muted">Shares Sold vs Total Shares</span>
          <h4 class="font-weight-bold">{{ metrics.sold_shares_percentage }}</h4>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="card mb-3">
        <div class="card-body">
          <span class="text-muted">Average Time To Sell a Share</span>
          <h4 class="font-weight-bold">
            {{ metrics.sold_shares_days_average }}
          </h4>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="card mb-3">
        <div class="card-body">
          <span class="text-muted">Action Sold Out vs Action Published</span>
          <h4 class="font-weight-bold">
            {{ metrics.soldout_actions_percentage }}
          </h4>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="card mb-3">
        <div class="card-body">
          <span class="text-muted">Average Time To Sell Out an Action</span>
          <h4 class="font-weight-bold">
            {{ metrics.soldout_actions_days_average }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<app-loader *ngIf="isLoading"></app-loader>
