<ng-container>

  <!-- Current wallet -->
  <div class="card mb-3">

    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <div class="font-weight-bold">
          Top Metrics
        </div>
      </div>
      <div class="pt-2 ml-1">
        <div class="d-flex flex-row justify-content-between align-items-center">
          <a class="small ml-2" href="#" [routerLink]="'/dashboard/top_metrics_sold_shares'">Sold Shares</a>
        </div>
        <div class="d-flex flex-row justify-content-between align-items-center">
          <a class="small ml-2" href="#" [routerLink]="null">Sold Actions</a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <div class="font-weight-bold">
          Vanity Metrics
        </div>
      </div>
      <div class="pt-2 ml-1">
        <div class="d-flex flex-row justify-content-between align-items-center">
          <a class="small ml-2" href="#" [routerLink]="null">Users</a>
        </div>
        <div class="d-flex flex-row justify-content-between align-items-center">
          <a class="small ml-2" href="#" [routerLink]="null">Actions</a>
        </div>
        <div class="d-flex flex-row justify-content-between align-items-center">
          <a class="small ml-2" href="#" [routerLink]="null">Support</a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <div class="font-weight-bold">
          Action Metrics
        </div>
      </div>
      <div class="pt-2 ml-1">
        <div class="d-flex flex-row justify-content-between align-items-center">
          <a class="small ml-2" href="#" [routerLink]="null">Support</a>
        </div>
        <div class="d-flex flex-row justify-content-between align-items-center">
          <a class="small ml-2" href="#" [routerLink]="null">Validation</a>
        </div>

      </div>
    </div>
  </div>

</ng-container>

