import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActionService, AuthService, CatalogService, QueryService, WalletService } from 'src/app/shared/services';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionModel, WalletModel, GoogleMaps } from '../../../../shared/models';
import { ALLOWED_VIDEO_FORMATS } from '../../../../shared/constants';
import { ToastrService } from 'ngx-toastr';
import { responseCodes } from '../../../../shared/response';

@Component({
  selector: 'app-wallet-actions-edit',
  templateUrl: './edit.component.html'
})
export class WalletActionsEditComponent {
  wallet: WalletModel;
  action: ActionModel;

  // form instance
  form!: FormGroup;
  loaderSubscription$: Subscription;
  themes: any;
  values: any;
  statuses: any;
  locationData: GoogleMaps;

  // UI Flag
  isLoading = true;
  isSubmitted = false;
  videoInfoMsg;
  currentTab = 'video';

  // Mode
  isCreateMode = false;

  // Upload Inprogress
  filesUploading = false;

  /**
   * @param authService
   * @param route
   * @param queryService
   * @param translateService
   * @param formBuilder
   * @param router
   * @param catalogService
   * @param walletService
   * @param actionService
   * @param toastrService
   */
  constructor(
    private route: ActivatedRoute,
    private queryService: QueryService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private router: Router,
    private catalogService: CatalogService,
    private walletService: WalletService,
    private actionService: ActionService,
    private toastrService: ToastrService,
    // public
    public authService: AuthService
  ) {
    this.loaderSubscription$ = this.route.params.subscribe(async params => {
      this.loadItem(+params.id, +params.action);
    });

    // Define video format error message
    const videoFormats = ALLOWED_VIDEO_FORMATS.join(', ');
    this.videoInfoMsg = this.translateService.instant('errors.file_upload_info', { 'video_format': videoFormats });
  }

  /**
   * @param walletId
   * @param actionId
   */
  async loadItem(walletId: number, actionId: number): Promise<void> {
    this.isLoading = true;

    // Load data
    this.themes = await this.catalogService.getAllThemes();
    this.values = await this.catalogService.getValues();
    this.statuses = await this.catalogService.getStatuses();
    this.wallet = new WalletModel(await this.walletService.loadWallet(walletId));

    this.isCreateMode = (actionId === 0);

    // Load/Create Action
    let tmpAction;
    if (!this.isCreateMode) {
      tmpAction = await this.walletService.loadSecuredAction(walletId, actionId);
    } else {
      tmpAction = {
        id: actionId,
        name: 'New Action',
        producer: this.wallet,
        remaining: 1000
      };
    }
    this.action = new ActionModel(tmpAction);

    // Build form
    this.form = this.getForm(this.action);

    this.isLoading = false;
  }

  /**
   *
   */
  getForm(action: ActionModel): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(action.id),
      name: new FormControl(action.name),
      video: new FormControl(action.video),
      actionDate: new FormControl(action.actionDate),
      description: new FormControl(action.description),
      budget: new FormControl(action.budget),
      objective: new FormControl(action.objective),
      result: new FormControl(action.result),
      value: new FormControl(action.value),
      producer: new FormControl(action.producer),
      themes: new FormControl(action.themes),
      fees: new FormControl(action.fees),
      score: new FormControl(action.score),

      // Google Map
      // country: new FormControl(action.country),
      // latitude: new FormControl(action.latitude),
      // longitude: new FormControl(action.longitude),
      // address: new FormControl(action.address)
    });
  }

  /**
   * @param theme
   */
  toggleTheme(theme: any): void {
    // Remove theme
    if (this.haveTheme(theme) === true) {
      const t = _.filter(this.form.get('themes').value, el => {
        return el.id !== theme.id;
      });
      this.form.get('themes').setValue(t);
      // Add theme
    } else {
      const t = this.form.get('themes').value;
      t.push(theme);
    }
  }

  /**
   * @param theme
   * @returns
   */
  haveTheme(theme: any): boolean {
    return _.filter(this.form.get('themes').value, el => {
      return el.id === theme.id;
    }).length > 0;
  }

  /**
   * @param idx
   */
  async removeFile(idx: number): Promise<any> {
    try {
      this.action.files = await this.actionService.removeVideoFile(idx, this.action.id);
    } catch (err: any) {
      if (err === (responseCodes.atleast_one_video_required as any)) {
        this.toastrService.error(this.translateService.instant('errors.file_remove_error'));
      }
    }
  }

  /**
   * @param idx
   */
  setCover(idx: number): void {
    this.queryService.q('GET', '/sec/actions/' + this.action.id + '/cover/' + idx).subscribe(
      evt => {
        this.action.files = evt;
      }
    );
  }

  /**
   * @param evt
   */
  refresh(evt): void {
    this.filesUploading = false;
    if (this.action.id === 0 && evt.id) {
      this.action = evt;
      this.router.navigate(['/wallets/' + this.wallet.id + '/actions/' + evt.id + '/edit']);
    } else {
      this.action.files = evt;
    }
  }

  getLocationData(evt): void {
    this.locationData = evt;
  }

  shouldDisableTab(): boolean {
    return this.isCreateMode || this.filesUploading;
  }

  /**
   */
  save(): boolean | void {
    if (!this.form || (this.form && !this.form.valid)) {
      return false;

    } else {
      this.isSubmitted = true;

      const payload = _.pickBy(this.form.value, el => el !== null);
      payload.themes = _.map(payload.themes, el => el.id);
      payload.producer = payload.producer.id;
      if (this.locationData) {
        payload.country = this.locationData.countryShortCode;
        payload.latitude = this.locationData.latitude;
        payload.longitude = this.locationData.longitude;
        payload.address = this.locationData.address;
      }

      let query;
      if (this.isCreateMode) {
        query = this.queryService.q('POST', '/sec/actions', payload);
      } else {
        query = this.queryService.q('PUT', '/sec/actions/' + this.action.id, payload);
      }
      query?.subscribe(res => {
        this.isSubmitted = false;
        this.action = new ActionModel(res);
        this.router.navigate(['/wallets/' + this.wallet.id + '/actions/' + res.id + '/edit']);
        this.toastrService.success('Saving done !');
      }, err => {
        console.log('err: ', err);
        this.isSubmitted = false;
        this.toastrService.error('Ooops !');
      });
    }
  }

  /**
   * @param tab
   */
  setTab(tab: string): void {
    if (!this.isCreateMode) {
      this.currentTab = tab;
    }
  }

  delete(): void {
    this.queryService.q('DELETE', '/sec/actions/' + this.action.id).subscribe(
      ret => {
        this.toastrService.success('Action deleted');
        this.router.navigate(['/wallets/' + this.wallet.id + '/actions']);
      }
    );
  }
}
