import { Component, OnInit } from '@angular/core';
import { AuthService, CatalogService, QueryService, WalletService } from '../../../shared/services';
import { WalletModel } from '../../../shared/models';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-pub-wallets-list',
  templateUrl: './list.component.html'
})
export class WalletsListComponent implements OnInit {
  isAdmin = false;
  isConnected = false;

  wallets: WalletModel[];

  themeFilter: FormControl = new FormControl(null);
  themeOptions: any;
  countryFilter: FormControl = new FormControl(null);
  countryOptions: any;

  isLoading = true;
  isFilterLoading = true;

  constructor(
    public authService: AuthService,
    public route: ActivatedRoute,
    public queryService: QueryService,
    private catalogService: CatalogService,
    private walletService: WalletService
  ) {
    this.isAdmin = this.authService.isAdmin();
    this.isConnected = this.authService.isConnected();
  }

  async ngOnInit(): Promise<void> {
    this.isFilterLoading = true;
    this.themeOptions = await this.catalogService.getThemes();
    this.countryOptions = await this.catalogService.getCountries();
    this.isFilterLoading = false;

    this.themeFilter.valueChanges.subscribe(evt => {
      this.loadItems();
    });
    this.countryFilter.valueChanges.subscribe(evt => {
      this.loadItems();
    });

    this.loadItems();
  }

  async loadItems(): Promise<void> {
    this.isLoading = true;
    this.wallets = await this.walletService.loadWalletsBy({
      criteria: this.getFilterPayload()
    });
    this.isLoading = false;
  }

  getFilterPayload(): any {
    return {
      themes: this.themeFilter.value ? [this.themeFilter.value] : [],
      country: this.countryFilter.value ?? null
    };
  }

}
