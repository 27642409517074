<ng-container *ngIf="!isLoading">

  <div class="row">
    <!--      </div>-->
    <div class="col">

      <!-- header -->
      <div class="card mb-3">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <h4 class="font-weight-bold mb-0">
              Validation
            </h4>
          </div>
        </div>
      </div>

      <!-- action -->
      <div class="card mb-3">
        <div class="card-body">

          <app-action-header
            [action]="action"
            [showScore]="false"
            [showStars]="false"
            [showStatus]="false">
          </app-action-header>

          <div class="my-3 text-center">
            <app-file-display [file]="action.files[0]" [cssClass]="'file active max'"></app-file-display>
          </div>

          <div class="row mb-2">
            <div class="col-12">
              <span class="small text-muted">Producer</span>
            </div>
            <div class="col-12 d-flex flex-row">
              <app-wallet-header
                [wallet]="action?.producer"
                [size]="'xs'"
                [showName]="true"
                [showScore]="false"
                [showLink]="true"
              ></app-wallet-header>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-12">
              <span class="small text-muted">Informations</span>
            </div>
            <div class="col-12 d-flex flex-wrap">
              <div class="p-1 px-2 rounded bg-light small mr-2">
                <i class="la la-globe la-lg mr-1"></i>
                <span>{{ country?.name }}</span>
              </div>
              <div *ngFor="let theme of action?.themes" class="p-1 px-2 rounded bg-light small mr-2">
                <i class="la la-lg {{theme.icon}} mr-1"></i>
                <span>{{theme.name}}</span>
              </div>
              <div class="p-1 px-2 rounded bg-light small mr-2">
                <i class="la la-dollar la-lg mr-1"></i>
                <span>{{action?.valueLevel}} budget</span>
              </div>
              <div *ngIf="action?.remaining > 0" class="p-1 px-2 rounded bg-light small mr-2">
                <i class="las la-money-check la-lg mr-1"></i>
                <span>{{action?.remaining}} shares</span>
              </div>
              <div *ngIf="action?.remaining === 0" class="p-1 px-2 rounded bg-warning small mr-2">
                <i class="las la-money-check la-lg mr-1"></i>
                <span>sold out !</span>
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-12">
              <span class="small text-muted">Objective</span>
            </div>
            <div class="col-12">
              <span *ngIf="action?.objective !== ''" class="mdtext">{{ action?.objective }}</span>
              <span *ngIf="!action?.objective">-</span>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-12">
              <span class="small text-muted">Description</span>
            </div>
            <div class="col-12">
              <span *ngIf="action?.description !== ''" class="mdtext">{{ action?.description }}</span>
              <span *ngIf="!action?.description">-</span>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-12">
              <span class="small text-muted">Budget</span>
            </div>
            <div class="col-12">
              <span *ngIf="action?.budget !== ''" class="mdtext">{{ action?.budget }}</span>
              <span *ngIf="!action?.budget">-</span>
            </div>
          </div>

          <!-- buttons -->
          <div class="d-flex justify-content-around align-items-center mt-3">
            <div>
              <button class="btn btn-sm btn-taamana" [routerLink]="'/wallets/' + wallet.id + '/ratings/efficiency'">
                Efficiency
                <i class="la la-arrow-right"></i>
              </button>
            </div>
          </div>

        </div>
      </div>

      <!-- content -->
      <!--      <div class="card mb-3">-->
      <!--        <div class="card-body">-->

      <!--          <div class="d-flex flex-row">-->
      <!--            <div>-->
      <!--              <app-action-header-->
      <!--                [action]="action"-->
      <!--                [showScore]="false"-->
      <!--                [showStars]="false"-->
      <!--                [showStatus]="false"-->
      <!--              ></app-action-header>-->

      <!--              <div class="d-flex flex-row mb-2">-->
      <!--                <div class="d-flex p-1 mb-1 rounded bg-light mr-2 small">-->
      <!--                  <span class="font-weight-bold mr-1">{{action?.valueLevel}}</span>-->
      <!--                  budget-->
      <!--                </div>-->
      <!--                <div class="d-flex p-1 mb-1 rounded bg-light mr-2 small">-->
      <!--                  <span class="font-weight-bold mr-1">{{country.name}}</span>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              <div *ngIf="action?.themes.length>0" class="d-flex flex-row mb-3">-->
      <!--                <div *ngFor="let theme of action?.themes" class="theme">-->
      <!--                  <div class="d-flex align-items-center ico active mr-2 p-1">-->
      <!--                    <i class="la {{theme.icon}} mr-1"></i>-->
      <!--                    <small>{{theme.name}}</small>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              <ng-container *ngIf="action?.objective">-->
      <!--                <label class="font-weight-bold">Objective</label>-->
      <!--                <p class="small">{{ action?.objective }}</p>-->
      <!--              </ng-container>-->
      <!--              <ng-container *ngIf="action?.description">-->
      <!--                <label class="font-weight-bold">Description</label>-->
      <!--                <p class="small">{{ action?.description }}</p>-->
      <!--              </ng-container>-->
      <!--              <ng-container *ngIf="action?.budget">-->
      <!--                <label class="font-weight-bold">Budget</label>-->
      <!--                <p class="small">{{ action?.budget }}</p>-->
      <!--              </ng-container>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div-->
      <!--            class="text-center"-->
      <!--            [class.col-10]="action.files.length>1"-->
      <!--            [class.col-12]="action.files.length<=1">-->
      <!--            <ng-container *ngIf="focusedFile">-->
      <!--              <app-file-display [file]="focusedFile" [cssClass]="'file xl'"></app-file-display>-->
      <!--            </ng-container>-->
      <!--            <ng-container *ngIf="!focusedFile">-->
      <!--              <img alt="action" class="file xl rounded" src="../../../../assets/img/action.jpg">-->
      <!--            </ng-container>-->
      <!--          </div>-->
      <!--          <div-->
      <!--            *ngIf="action.files.length>1" class="d-flex justify-content-center flex-row overflow-auto">-->
      <!--            <div *ngFor="let file of action.files" (click)="setFocusedFile(file)" class="mb-2">-->
      <!--              <app-file-display [file]="file"-->
      <!--                                [cssClass]="'file sm cursor-pointer' + (file===focusedFile?' active':'')"></app-file-display>-->
      <!--            </div>-->


    </div>
  </div>

</ng-container>

<app-loader *ngIf="isLoading"></app-loader>
