export const responseCodes = {
  ok: 'ok',
  no_available_action: 'no_available_action',
  atleast_one_video_required: 'atleast_one_video_required'
};

export const isValidResponse = (response: any): boolean => {
  return response?.code === responseCodes.ok;
};

export const getResponseData = (response: any, isObject: boolean = false): any => {
  if (!isValidResponse(response)) {
    throw response.code;
  }

  return isObject ? response.data[0] : response.data;
};
