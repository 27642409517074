export class ShareModel {
  id: number;
  owner: {id: number; name: string; } | number; // details or ID
  seller: {id: number; name: string; } | number; // details or ID
  action: {id: number; name: string; } | number; // details or ID
  stamp: string;
  qty: number;
  amount: number;
  stars: number;
  message: string;

  constructor(data) {
    this.id = data.id ? data.id : 0;
    this.owner = data.owner ?? {};
    this.seller = data.seller ?? {};
    this.action = data.action ?? {};

    this.stamp = data.stamp ? data.stamp : null;
    this.qty = data.qty ? data.qty : 0;
    this.amount = data.amount ? data.amount : 0;
    this.stars = data.stars ? data.stars : 0;
    this.message = data.message ? data.message : '';
  }
}
