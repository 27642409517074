import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AuthService, CatalogService, QueryService, WalletService, RatingService} from 'src/app/shared/services';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ActionModel, WalletModel, RatingModel} from '../../../shared/models';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-wallet-ratings-efficiency',
  templateUrl: './efficiency.component.html'
})
export class WalletRatingsEfficiencyComponent implements OnInit, OnDestroy {
  wallet: WalletModel;
  action: ActionModel;
  // TODO Should be RatingModel
  rating: RatingModel;

  localValue: FormControl = new FormControl(0);

  loaderSubscription$: Subscription;

  // UI Flag
  isLoading = true;
  isSubmitted = false;

  /**
   * @param authService
   * @param route
   * @param queryService
   * @param translateService
   * @param router
   * @param catalogService
   * @param walletService
   * @param ratingService
   */
  constructor(
    private route: ActivatedRoute,
    private queryService: QueryService,
    private translateService: TranslateService,
    private router: Router,
    private catalogService: CatalogService,
    private walletService: WalletService,
    // public
    public ratingService: RatingService,
    public authService: AuthService
  ) {
    this.loaderSubscription$ = this.route.params.subscribe(async params => {
      this.loadItem(+params.id);
    });
  }

  /**
   *
   */
  ngOnInit(): void {
    this.localValue.valueChanges.subscribe(evt => {
      this.setLocalValue(evt);
    });
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.loaderSubscription$.unsubscribe();
  }

  /**
   * @param walletId
   */
  async loadItem(walletId: number): Promise<void> {
    this.isLoading = true;

    // Load Wallet
    this.wallet = new WalletModel(await this.walletService.loadWallet(walletId));

    // Load current Rating
    if (this.ratingService.getRating() === undefined) {
      this.router.navigate(['/wallets/' + this.wallet.id + '/ratings/new']);
    }
    this.localValue.setValue(this.ratingService.getRating().efficiency, {emitEvent: false});
    this.action = this.ratingService.getAction();

    this.isLoading = false;
  }

  /**
   * @param value
   */
  setLocalValue(value: number): void {
    this.ratingService.rating.efficiency = value;
    this.ratingService.calculateScore();
  }
}
