import {Component, Input} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-file-display',
  templateUrl: './file-display.component.html'
})
export class FileDisplayComponent {
  @Input() file: any;
  @Input() size: ('xs'|'sm'|'md'|'lg'|'xl') = 'sm';
  @Input() cssClass: string;

  env: any;

  constructor() {
    this.env = environment;
  }
}
