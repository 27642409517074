import {Component} from '@angular/core';
import {AuthService} from '../shared/services';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html'
})
export class PublicComponent {
  isLoading = true;
  isConnected: boolean;

  constructor(
    private authService: AuthService
  ) {
    this.isConnected = this.authService.isConnected();
  }
}
