import { Component, OnInit } from '@angular/core';
import { CatalogService, RegisterService } from '../../../shared/services';

@Component({
  selector: 'app-reg-step-1',
  templateUrl: './step.component.html',
})
export class RegStepLocationComponent implements OnInit {
  countries: any;

  constructor(
    private catalogService: CatalogService,
    public registerService: RegisterService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.countries = await this.catalogService.getAllCountries();
  }

  isDisabled(): boolean {
    return !this.registerService.user.country;
  }
}
