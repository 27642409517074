<div class="container-fluid h-100">
  <div class="row home h-100">
    <div class="col-4 pane-left-picture">
      <app-header-reg-left></app-header-reg-left>
      <div class="content">
        <img alt="onboarding" src="../../assets/img/onboarding.png"/>
      </div>
    </div>
    <div class="col-8 pane-right">
      <app-header-reg-right></app-header-reg-right>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
