import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-action-stars',
  templateUrl: './action-stars.component.html'
})
export class ActionStarsComponent implements OnInit {
  @Input() stars = 0;
  @Input() size = 'sm';
  @Input() showValue = true;

  aCompleteStars: any = [];
  aRemaningStars: any = [];

  constructor() {
  }

  ngOnInit(): void {
    let completeStars = Math.floor(this.stars);
    const rest = this.stars - completeStars;
    if (rest > 0.5) {
      completeStars++;
    }
    this.aCompleteStars = new Array(completeStars);
    this.aRemaningStars = new Array(5 - completeStars);
  }
}
