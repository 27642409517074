import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    'html { height: 100%; }',
    'body { height: 100%; }',
    '.test { font-weight: bold; }'
  ]
})
export class MainComponent {

  constructor() {
  }
}
