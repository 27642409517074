import {Component, Input, OnInit} from '@angular/core';
import {AuthService, CatalogService} from '../../services';
import { ActionModel, WalletModel } from '../../models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-action-card',
  templateUrl: './action-card.component.html'
})
export class ActionCardComponent implements OnInit {
  @Input() action: ActionModel;
  @Input() wallet: WalletModel;
  @Input() isNew = false;

  country: { name: string; code: string; };

  isAdmin = false;
  isConnected = false;


  constructor(
    private authService: AuthService,
    private router: Router,
    private catalogService: CatalogService
  ) {
    this.isAdmin = this.authService.isAdmin();
    this.isConnected = this.authService.isConnected();
  }

  async ngOnInit(): Promise<void> {
    this.country = await this.catalogService.getCountry(this.wallet.country);
  }

  goToDetails(event: any): void {
    event.stopPropagation();
    this.router.navigate(['/wallets/' + this.wallet.id + '/actions/' + this.action.id]);
  }
}
