<ng-container *ngIf="!isLoading">

  <div class="row">
    <div class="col">

      <!-- header -->
      <div class="card mb-3">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <h4 class="font-weight-bold mb-0">
              Transactions history
            </h4>
            <div class="d-flex flex-column align-items-center justify-content-center ml-5">
              <div class="small text-muted">balance</div>
              <h3 class="font-weight-bold mb-0"
                  [class.text-danger]="wallet?.balance<0"
                  [class.text-success]="wallet?.balance>=0">
                D {{wallet?.balance}}
              </h3>
            </div>
            <div class="ml-auto">
              <button (click)="deposit()" class="btn btn-sm btn-taamana">
                <i class="la la-sign-in-alt la-lg"></i>
                Deposit
              </button>
              <button (click)="withdraw()" class="btn btn-sm btn-taamana ml-2">
                <i class="la la-sign-out-alt la-lg"></i>
                Withdraw
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-3">
        <div class="list-group list-group-flush">
          <div *ngIf="transactions.length === 0" class="list-group-item">
            <h6 class="card-title font-weight-bold mb-0">
              No transactions found
            </h6>
          </div>
          <div *ngFor="let transaction of transactions" class="list-group-item">
            <div class="d-flex flex-row w-100">
              <div class="mr-2">
                <i *ngIf="transaction?.amount>=0" class="la la-sign-in-alt text-success la-lg"></i>
                <i *ngIf="transaction?.amount<0" class="las la-sign-out-alt text-danger la-lg"></i>
              </div>
              <div class="mr-2 small">
                <div>{{transaction.description}}</div>
                <div class="text-muted">
                  {{transaction?.stamp}}
                </div>
              </div>
              <div class="ml-auto small font-weight-bold"
                   [class.text-danger]="transaction?.amount<0"
                   [class.text-success]="transaction?.amount>=0">
                D {{transaction?.amount}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isLoading">
  <app-loader></app-loader>
</ng-container>
