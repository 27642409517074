import { Injectable } from '@angular/core';
import { ActionModel } from '../models';
import { QueryService } from './query.service';
import { getResponseData } from '../response';

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  action: ActionModel;

  constructor(
    private queryService: QueryService
  ) {
  }

  /**
   * @param videoId
   * @param actionId
   */
  async removeVideoFile(videoId, actionId): Promise<any> {
    const response = await this.queryService.q('GET', '/sec/actions/' + actionId + '/remove/' + videoId).toPromise();
    return getResponseData(response, false);
  }

}
