import { Component, OnDestroy } from '@angular/core';
import { AuthService, CatalogService, QueryService, WalletService, RatingService } from 'src/app/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionModel, WalletModel, RatingModel } from '../../../shared/models';

@Component({
  selector: 'app-wallet-ratings-action',
  templateUrl: './action.component.html'
})
export class WalletRatingsActionComponent implements OnDestroy {
  wallet: WalletModel;
  action: ActionModel;
  // TODO Should be RatingModel
  rating: RatingModel;
  country: { name: string; code: string; };

  focusedFile: any;
  loaderSubscription$: Subscription;

  // UI Flag
  isLoading = true;
  isSubmitted = false;

  /**
   * @param authService
   * @param route
   * @param queryService
   * @param translateService
   * @param router
   * @param catalogService
   * @param walletService
   * @param ratingService
   */
  constructor(
    private route: ActivatedRoute,
    private queryService: QueryService,
    private translateService: TranslateService,
    private router: Router,
    private catalogService: CatalogService,
    private walletService: WalletService,
    // public
    public ratingService: RatingService,
    public authService: AuthService
  ) {
    this.loaderSubscription$ = this.route.params.subscribe(async params => {
      this.loadItem(+params.id);
    });
  }

  ngOnDestroy(): void {
    this.loaderSubscription$.unsubscribe();
  }

  /**
   * @param walletId
   */
  async loadItem(walletId: number): Promise<void> {
    this.isLoading = true;

    // Load Wallet
    this.wallet = new WalletModel(await this.walletService.loadWallet(walletId));

    // Load current Rating
    this.rating = this.ratingService.getRating();
    if (this.rating === undefined) {
      this.router.navigate(['/wallets/' + this.wallet.id + '/ratings/new']);
    }
    this.action = this.ratingService.getAction();

    this.country = await this.catalogService.getCountry(this.action.country);

    // Focused file = default first one if any
    this.focusedFile = this.action.files.length > 0 ? this.action.files[0] : undefined;

    this.isLoading = false;
  }

  /**
   * @param file
   */
  setFocusedFile(file): void {
    this.focusedFile = file;
  }
}
