<ng-container *ngIf="['jpg', 'jpeg', 'png', 'gif'].includes(file?.ext)">
  <img
    class="{{ cssClass }}"
    src="{{ env.apiUrl }}/pub/actions/{{ file.name }}"
  />
</ng-container>
<ng-container *ngIf="['mov', 'avi', 'mp4', 'mpeg', 'mpg'].includes(file?.ext)">
  <video class="{{ cssClass }}" controls>
    <source
      src="{{ env.apiUrl }}/pub/actions/{{ file.name }}"
      [attr.type]="['mov', 'mp4'].includes(file.ext) ? 'video/mp4' : 'video/' + file.ext"
    />
    Sorry, your browser doesn't support embedded videos.
  </video>
</ng-container>
