import { Component, OnInit } from '@angular/core';
import { AuthService, QueryService, CatalogService, WalletService } from '../../../../shared/services';
import { ActionModel, WalletModel } from '../../../../shared/models';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-wallet-actions',
  templateUrl: './list.component.html'
})
export class WalletActionsListComponent implements OnInit {
  wallet: WalletModel;
  actions: ActionModel[] = [];

  statusFilter: FormControl = new FormControl(5);
  statusOptions: any;

  loaderSubscription$: Subscription;

  isLoading = true;

  constructor(
    public authService: AuthService,
    public route: ActivatedRoute,
    public queryService: QueryService,
    private catalogService: CatalogService,
    private walletService: WalletService
  ) {
    this.loaderSubscription$ = this.route.params.subscribe(async params => {
      this.isLoading = true;
      await this.loadWallet(+params.id);
      await this.loadActions(+params.id);

      // console.log(this.wallet);
      // console.log(this.actions);
      // console.log(this.isLoading);

      this.isLoading = false;
    });
  }

  async ngOnInit(): Promise<void> {
    this.statusOptions = await this.catalogService.getStatuses();
    this.statusFilter.valueChanges.subscribe(async evt => {
      this.isLoading = true;
      await this.loadActions(this.wallet.id, evt);
      this.isLoading = false;
    });
  }

  async loadWallet(walletId: number): Promise<void> {
    this.wallet = new WalletModel(await this.walletService.loadWallet(walletId));
  }

  async loadActions(walletId: number, status?: number): Promise<void> {
    const tmpActions = _.map(
      await this.walletService.loadProducedActions(walletId),
      el => new ActionModel(el)
    );
    if (status) {
      this.actions = _.filter(tmpActions, (el) => {
        return el.status === status;
      });
    } else {
      this.actions = tmpActions;
    }
  }
}
