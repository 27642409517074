import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { QueryService } from './query.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  public notifications: any = {
    ratings: false,
    profiles: []
  };

  constructor(
    private queryService: QueryService
  ) {
  }

  /**
   *
   * @param userId
   * @param walletId
   */
  async initNotifications(
    userId: number,
    walletId: number
  ): Promise<void> {
    // Reset local notifications
    this.notifications = {
      ratings: false,
      profiles: []
    };

    // Load notifications
    this.notifications = await this.loadNotifications(walletId);
  }

  /**
   *
   */
  getNotifications(walletId: number): any {
    const found = _.find(this.notifications.profiles, { id: walletId });
    if (!found) {
      return 0;
    } else {
      return found.actions?.length;
    }
  }

  /**
   *
   */
  isActionNew(
    walletId: number,
    actionId: number
  ): boolean {
    const found = _.find(this.notifications.profiles, { id: walletId });
    if (!found) {
      return false;
    } else {
      return found.actions.includes(actionId);
    }
  }

  /**
   *
   * @param walletId
   */
  async loadNotifications(walletId: number): Promise<any[]> {
    const $q = this.queryService.q('POST', '/sec/notifications', {
      walletId
    });
    return await firstValueFrom($q);
  }

  /**
   *
   * @param walletId
   */
  profileHaveNotification(walletId: number): boolean {
    return _.findIndex(this.notifications.profiles, { id: walletId }) > -1;
  }

  /**
   *
   * @param walletId
   */
  haveRatings(): boolean {
    return this.notifications.ratings;
  }

  /**
   *
   * @param profileId
   * @param actionId
   */
  async seeAction(profileId: number, actionId: number): Promise<void> {
    const $q = this.queryService.q('GET', '/sec/notifications/see/a/' + profileId + '/' + actionId);
    this.notifications = await firstValueFrom($q);
  }
}
