import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AuthService, CatalogService, QueryService, WalletService} from 'src/app/shared/services';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ActionModel, WalletModel} from '../../../../shared/models';

@Component({
  selector: 'app-wallet-actions-confirm',
  templateUrl: './confirm.component.html'
})
export class WalletActionsConfirmComponent {
  wallet: WalletModel;
  action: ActionModel;

  // form instance
  form!: FormGroup;
  loaderSubscription$: Subscription;
  themes: any;
  countries: any;
  values: any;
  statuses: any;

  // UI Flag
  isLoading = true;
  isSubmitted = false;

  /**
   * @param authService
   * @param route
   * @param queryService
   * @param translateService
   * @param formBuilder
   * @param router
   * @param catalogService
   * @param walletService
   */
  constructor(
    private route: ActivatedRoute,
    private queryService: QueryService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private router: Router,
    private catalogService: CatalogService,
    private walletService: WalletService,
    // public
    public authService: AuthService
  ) {
    this.loaderSubscription$ = this.route.params.subscribe(async params => {
      this.loadItem(+params.id, +params.action);
    });
  }

  /**
   * @param walletId
   * @param actionId
   */
  async loadItem(walletId: number, actionId: number): Promise<void> {
    this.isLoading = true;

    // Load Wallet
    this.wallet = new WalletModel(await this.walletService.loadWallet(walletId));

    // Load/Create Action
    let tmpAction;
    if (actionId !== 0) {
      tmpAction = await this.walletService.loadSecuredAction(walletId, actionId);
    } else {
      tmpAction = {
        id: actionId,
        name: 'New Action Name',
        status: 1,
        video: '',
        score: 0,
        description: 'Here goes the description',
        producer: this.wallet,
        themes: [],
        remaining: 1000,
        country: null,
        fees: 10.00,
      };
    }
    this.action = new ActionModel(tmpAction);

    // Build form
    this.form = this.getForm(this.action);

    this.isLoading = false;
  }

  /**
   *
   */
  getForm(action: ActionModel): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(action.id),
      name: new FormControl(action.name),
      video: new FormControl(action.video),
      description: new FormControl(action.description),
      value: new FormControl(action.value),
      producer: new FormControl(action.producer),
      themes: new FormControl(action.themes),
      country: new FormControl(action.country),
      fees: new FormControl(action.fees),
      score: new FormControl(action.score)
    });
  }

  /**
   */
  save(): boolean | void {
    if (!this.form || (this.form && !this.form.valid)) {
      return false;

    } else {
      this.isSubmitted = true;

      this.queryService.q('POST', '/sec/actions/' + this.action.id + '/submit', {})?.subscribe(res => {
        this.isSubmitted = false;
        this.router.navigate(['/wallets/' + this.wallet.id + '/actions/' + this.action.id + '/confirm']);
      }, err => {
        console.log('err: ', err);
        this.isSubmitted = false;
      });
    }
  }
}
