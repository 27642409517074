import {WalletModel} from './WalletModel';

export class ActionModel {
  id: number;
  name: string;
  status: number;
  video: string;
  score?: number;
  objective: string;
  result: string;
  budget: string;
  description: string;
  address: string;
  latitude: number;
  longitude: number;
  actionDate: Date;
  value: number;
  valueLevel: string;
  unitPrice: number;
  producer: WalletModel;
  themes: { id: number; name: string; icon: string; }[];
  country: string;
  stamp: string;
  fees: number;
  files: any;
  stars: number;
  submissionFee: any;

  // Calculated on the back end
  remaining: number;
  remainingPercentage: number;

  // TODO To Define exact structure
  supporters: any;
  shares: any;

  constructor(data) {
    this.id = data.id ?? 0;
    this.name = data.name ?? '';
    this.status = data.status ?? 0;
    this.video = data.video ?? '';
    this.score = data.score ?? null;
    this.objective = data.objective ?? null;
    this.result = data.result ?? null;
    this.actionDate = data.action_date ?? null;
    this.description = data.description ?? '';
    this.budget = data.budget ?? '';
    this.value = data.value ?? 0;
    this.valueLevel = data.valueLevel ?? 0;
    this.unitPrice = data.unitPrice ?? 0.00;
    this.producer = data.producer ?? {};
    this.themes = data.themes ?? [];
    this.country = data.country ?? '';
    this.stamp = data.stamp ?? null;
    this.fees = data.fees ?? 10.00;
    this.submissionFee = data.submissionFee ?? 0;
    this.files = data.files ?? [];
    this.stars = data.stars ?? null;
    this.longitude = data.longitude ?? null;
    this.latitude = data.latitude ?? null;
    this.address = data.address ?? null;

    this.remaining = data.remaining ?? 0;
    this.remainingPercentage = data.remainingPercentage ?? 0;

    this.supporters = data.supporters ?? [];
    this.shares = data.shares ?? [];
  }

  isPublishable(): boolean {
    return this.name !== '' &&
      this.description !== '' &&
      this.objective !== '' &&
      this.result !== '' &&
      this.budget !== '' &&
      this.valueLevel !== '' &&
      this.actionDate !== null &&
      this.country !== '';
  }
}

