import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-action-score',
  templateUrl: './action-score.component.html'
})
export class ActionScoreComponent {
  @Input() score = 0;
  @Input() size = 'sm';

  constructor() {}
}
