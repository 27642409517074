import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { QueryService } from '../../services';
import { ActionModel } from '../../models';
import { ALLOWED_VIDEO_FORMATS } from '../../constants';
import { ToastrService } from 'ngx-toastr';

const MIN_VIDEO_SIZE_MB = 5000;
const MAX_VIDEO_SIZE_MB = 50000000;

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
})
export class FileUploadComponent {
  @Input() action: ActionModel;
  @Output() filesUploaded = new EventEmitter<any>();
  @Output() filesUploading = new EventEmitter();

  entityType = 'location';
  imageServiceUrl = 'api/images';

  files = [];
  progressPercentage = 0;
  showProgressBar = false;

  public fileUploadControl = new FileUploadControl()
    .setListVisibility(false)
    .setValidators([
      FileUploadValidators.sizeRange({ minSize: MIN_VIDEO_SIZE_MB, maxSize: MAX_VIDEO_SIZE_MB }),
      FileUploadValidators.accept(ALLOWED_VIDEO_FORMATS)
    ]);

  constructor(
    private queryService: QueryService,
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) {
    this.fileUploadControl.valueChanges.subscribe((data: any) => this.statusChanged(data));
  }

  /**
   * @param files
   */
  statusChanged(files: []): void {
    if (!files.length) {
      return;
    }
    const formData: FormData = new FormData();
    formData.append('producer', this.action.producer.id as any);
    files.forEach(file => {
      formData.append('files[]', file);
    });
    this.showProgressBar = true;
    if (!this.fileUploadControl.valid) {
      this.showProgressBar = false;
      setTimeout(() => {
        this.fileUploadControl.clear();
        this.fileUploadControl.disable(false);
      }, 500);
      this.toastrService.error(this.translateService.instant('errors.file_upload_error'));
    } else {
      this.fileUploadControl.disable(true);
      this.filesUploading.emit();
      this.queryService.queryWithProgressBar('POST', '/sec/actions/' + this.action.id + '/upload', formData)
        .subscribe((ret) => {
          
          if (ret.type === HttpEventType.UploadProgress) {
            // This is an upload progress event. Compute and show the % done:
            this.progressPercentage = Math.round(100 * ret.loaded / ret.total);
            
          } else if (ret instanceof HttpResponse) {
            this.progressPercentage = 0;
            this.showProgressBar = false;
            setTimeout(() => {
              this.fileUploadControl.clear();
              this.fileUploadControl.disable(false);
            }, 500);
            this.filesUploaded.emit(ret.body);
            this.toastrService.success('All good :)');
          }

        },
          (err) => {
            this.showProgressBar = false;
            setTimeout(() => {
              this.fileUploadControl.clear();
              this.fileUploadControl.disable(false);
            }, 500);
            this.toastrService.error('Could not upload file !');
          }
        );
    }
  }
}
