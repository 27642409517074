<ng-container *ngIf="!isLoading">

  <div class="row">
    <div class="col">

      <!-- header -->
      <div class="card mb-3">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <h4 class="font-weight-bold mb-0 mr-2">
              Validation -
            </h4>
            <app-action-header
              [action]="action"
              [showScore]="false"
              [showStars]="false"
              [showStatus]="false"
            ></app-action-header>
          </div>
        </div>
      </div>

      <!-- content -->
      <div class="card mb-3">
        <div class="card-body">
          <h6 class="card-title">
            Effectiveness
          </h6>

          <p class="card-text">
            How would you rate this action’s Effectiveness ?
          </p>

          <p class="card-text">
            Effectiveness in this context relates to how well the action aligns with the stated objectives.
          </p>

          <p class="card-text">
            A low score means the action was ineffective.
          </p>

          <p class="card-text">
            A high score means the action was effective.
          </p>

          <div class="mx-4 mt-5">
            <nouislider [min]="0" [max]="100" [step]="1" [formControl]="localValue"></nouislider>
          </div>

          <div class="text-center">
            <h3 class="display-4 font-weight-bold">{{ ratingService.rating.effectiveness }}</h3>
          </div>

          <div class="d-flex justify-content-around align-items-center mt-3">
            <div>
              <a class="btn btn-sm btn-taamana" [routerLink]="'/wallets/' + wallet.id + '/ratings/efficiency'">
                <i class="la la-arrow-left"></i>
                Efficiency
              </a>
            </div>
            <div>
              <a class="btn btn-sm btn-taamana" [routerLink]="'/wallets/' + wallet.id + '/ratings/quality'">
                Quality
                <i class="la la-arrow-right"></i>
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</ng-container>

<app-loader *ngIf="isLoading"></app-loader>
