import { Component } from '@angular/core';
import { AuthService, Broadcaster, CatalogService, QueryService, WalletService } from '../../../../shared/services';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { WalletModel } from '../../../../shared/models';
import {
  WalletTransactionsDepositModalComponent,
  WalletTransactionsWithdrawModalComponent
} from '../../../index';

@Component({
  selector: 'app-wallet-transactions',
  templateUrl: './list.component.html'
})
export class WalletTransactionsListComponent {
  wallet: WalletModel;
  transactions: any;
  transactionTypes: any;

  loaderSubscription$: Subscription;

  isLoading = true;

  bsModalRef: BsModalRef;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private queryService: QueryService,
    private walletService: WalletService,
    private catalogService: CatalogService,
    private bsModalService: BsModalService,
    private broadcaster: Broadcaster
  ) {
    this.loaderSubscription$ = this.route.params.subscribe(async params => {
      this.loadItems(+params.id);
    });
  }

  async loadItems(walletId: number): Promise<void> {
    this.isLoading = true;
    this.wallet = new WalletModel(await this.walletService.loadWallet(walletId));
    this.transactions = await this.walletService.loadTransactions(walletId);
    this.transactionTypes = await this.catalogService.getTransactionsTypes();
    this.broadcaster.broadcast('walletDura');
    this.isLoading = false;
  }

  deposit() {
    this.router.navigate(['/wallets/' + this.wallet.id + '/transactions/deposit']);
  }

  withdraw(): void {
    this.bsModalRef = this.bsModalService.show(WalletTransactionsWithdrawModalComponent, {
      class: 'modal-dialog-centered'
    });

    this.bsModalRef.content.callbackFn = (payload: any) => {
      this.queryService.q('POST', '/sec/wallets/' + this.wallet.id + '/withdraw', payload).subscribe(
        () => {
          this.broadcaster.broadcast('walletDura', {});
          this.loadItems(this.wallet.id);
        },
        err => {
          console.log(err);
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = (evt: any) => {
      this.bsModalRef?.hide();
    };
  }
}
