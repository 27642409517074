import { Component, Input, OnInit } from '@angular/core';
import { ActionModel, WalletModel } from '../../models';
import { SOCIAL_SHARE_CHANNELS } from '../../../shared/constants';

@Component({
  selector: 'app-action-header',
  templateUrl: './action-header.component.html'
})
export class ActionHeaderComponent implements OnInit {
  @Input() wallet?: WalletModel;
  @Input() action: ActionModel;

  @Input() showWalletBackLink = true;
  @Input() showSupportButton = true;
  @Input() showStatus = true;
  @Input() showScore = true;
  @Input() showStars = true;
  @Input() size?: string;
  @Input() isNew = false;
  @Input() showStamp = true;
  @Input() showShareButtons = false;

  channels = SOCIAL_SHARE_CHANNELS;

  constructor() {
  }

  ngOnInit(): void {
    if (!this.size) {
      this.size = 'md';
    }
    // console.log(this.action);
    if (!this.action || this.action.status !== 3) {
      this.showWalletBackLink = false;
      this.showSupportButton = false;
      this.showScore = false;
      this.showStars = false;
    } else {
      if (!this.action.stars) {
        this.showStars = false;
      }
    }
    if (!this.wallet) {
      this.showWalletBackLink = false;
      this.showSupportButton = false;
    }
  }
}
