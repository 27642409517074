import { Component, Input } from '@angular/core';
import { WalletModel } from '../../../../shared/models';

@Component({
  selector: 'app-pub-wallet-card',
  templateUrl: './card.component.html'
})
export class WalletCardComponent {
  @Input() wallet: WalletModel;
  @Input() index?: number;

  constructor(
  ) {
  }
}
