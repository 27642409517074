<ng-container *ngIf="!isLoading">

  <div class="row">
    <div class="col">

      <!-- header -->
      <div class="card mb-3">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <h4 class="font-weight-bold mb-0 mr-2">
              Validation -
            </h4>
            <app-action-header
              [action]="action"
              [showScore]="false"
              [showStars]="false"
              [showStatus]="false"
            ></app-action-header>
          </div>
        </div>
      </div>

      <!-- content -->
      <div class="card mb-3">
        <div class="card-body">
          <h6 class="card-title">
            Comment
          </h6>

          <p class="card-text">
            Please provide a public comment or a feedback to help the producers improve !
          </p>

          <div class="mx-4 mt-3">
            <textarea class="form-control" [formControl]="localValue"></textarea>
          </div>

          <div class="d-flex justify-content-around align-items-center mt-3">
            <div>
              <a class="btn btn-sm btn-taamana" [routerLink]="'/wallets/' + wallet.id + '/ratings/videoquality'">
                <i class="la la-arrow-left"></i>
                Video Quality
              </a>
            </div>
            <div>
              <a class="btn btn-sm btn-taamana" [routerLink]="'/wallets/' + wallet.id + '/ratings/summary'">
                Summary
                <i class="la la-arrow-right"></i>
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</ng-container>

<app-loader *ngIf="isLoading"></app-loader>
