import { Component, Input, OnInit } from '@angular/core';
import { WalletModel } from '../../models';
import { Broadcaster, WalletService } from '../../services';
import { SOCIAL_SHARE_CHANNELS } from '../../../shared/constants';

@Component({
  selector: 'app-wallet-header',
  templateUrl: './wallet-header.component.html'
})
export class WalletHeaderComponent implements OnInit {
  @Input() wallet?: WalletModel;
  @Input() showScore = true;
  @Input() showFollow = true;
  @Input() showLink = false;
  @Input() showName = true;
  @Input() showBoldName = false;
  @Input() size?: string;
  @Input() showShareButtons = false;

  currentWallet: WalletModel;
  isFollowing = false;
  channels = SOCIAL_SHARE_CHANNELS;

  constructor(
    private walletService: WalletService,
    private broadcaster: Broadcaster
  ) {
  }

  ngOnInit(): void {
    if (!this.size) {
      this.size = 'md';
    }
    this.currentWallet = this.walletService.getCurrentWallet();
    this.isFollowing = this.checkIsFollowing(this.wallet.id);
  }

  /**
   * @param ev
   * @param targetWallet
   */
  async follow(ev: Event, targetWallet: WalletModel): Promise<void> {
    ev.stopPropagation();

    const ret = await this.walletService.follow(this.currentWallet, targetWallet);
    this.currentWallet.followed = ret.data;
    console.log(this.currentWallet.followed, this.checkIsFollowing(this.wallet.id));
    this.isFollowing = this.checkIsFollowing(this.wallet.id);
    this.broadcaster.broadcast('walletNetwork', {});
  }

  /**
   * @param targetId
   */
  checkIsFollowing(targetId: number): boolean {
    if (!this.currentWallet) {
      return false;
    }
    return this.currentWallet.followed.includes(targetId);
  }
}
