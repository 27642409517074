<div class="d-flex align-items-center">
  <ng-content></ng-content>
  <div
    class="avatar {{size}} d-flex d-inline-flex justify-content-center align-items-center text-truncate"
    [style.background-color]="wallet.color ? wallet.color : '#00766c'"
    tooltip="{{ wallet.name }}">
    {{ wallet.name | slice:0:1}}
  </div>
  <div
    *ngIf="showName"
    class="d-flex align-items-center {{size}}"
    [class.ml-2]="['md', 'lg', 'xl'].includes(size)"
    [class.ml-1]="!['md', 'lg', 'xl'].includes(size)"
    [class.notification]="showBoldName || notifications > 0">
    {{ wallet.name }}
    <span *ngIf="notifications > 0" class="ml-2 badge badge-danger badge-sm">{{ notifications }}</span>
  </div>
</div>
