<ng-container>
  <div class="row">
    <div class="col">
      <!-- content -->
      <div class="card mb-3">
        <div class="card-body">
          <h6 class="card-title">
            Error
          </h6>

          <p class="card-text">
            {{ 'adddura.msg-failure' | translate }}
          </p>
          <div class="text-center">
            <button [routerLink]="'/wallets/' + walletId + '/transactions'"
            class="btn btn-taamana btn-light">
            <i class="la la-arrow-left"></i> {{ 'adddura.back-to-transactions' | translate }}
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</ng-container>

