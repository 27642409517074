<div class="content">
  <div class="row">
    <div class="col-12">
      <h2 class="font-weight-bold">Member code</h2>
    </div>
    <div class="col-12">
      <div class="small text-muted">
        Your invitation code from an existing Profile. Have none ? Contact us :)
      </div>
    </div>
    <div class="col-12 mt-3">
      <div>
        <input [(ngModel)]="registerService.token" class="form-control" type="text">
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12 d-flex justify-content-center">
      <button [disabled]="isDisabled()" class="btn wide btn-sm btn-taamana" (click)="checkCode()">
        next
        <i class="la la-arrow-right"></i>
      </button>
    </div>
  </div>

</div>
