<div class="container-fluid h-100">
  <div class="row register h-100">
    <div class="col-4 pane-left-steps">

      <app-header-reg-left></app-header-reg-left>

      <!-- image -->
      <div class="content">
        <div class="steps">
          <div *ngFor="let step of steps" [routerLink]="step.status > 1 ? step.url : null" class="step">
            <div [class.active]="step.status > 1" [class.inactive]="step.status <= 1" class="marker">
              <ng-container *ngIf="step.status < 3">{{ step.rank }}</ng-container>
              <ng-container *ngIf="step.status === 3"><i class="la la-check"></i></ng-container>
            </div>
            <div class="name">{{ step.name }}</div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-8 pane-right">

      <app-header-reg-right></app-header-reg-right>

      <router-outlet></router-outlet>

    </div>
  </div>
</div>
