<div class="modal-header d-flex justify-content-between align-items-center">
  <h5 class="modal-title font-weight-bold">Confirm support</h5>
  <div class="d-flex ml-auto mr-1">
    <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<div class="modal-body">

  <app-action-header
    [action]="action"
    [showSupportButton]="false"
    [showWalletBackLink]="false"
    [showStatus]="false"
    [showScore]="true"
    [showStars]="false"
    [size]="'md'">
  </app-action-header>

  <p class="mt-2">
    Please confirm your support for <span class="font-weight-bold">{{ share.qty }}</span> shares of this action,
    for a total of <span class="font-weight-bold">D{{ share.amount}}</span>.
  </p>

  <p class="mt-2">
    Your contribution will be published on the action's page and all of the DURA collected will be paid to the communities.
  </p>

</div>

<div class="modal-footer d-flex justify-content-around">
  <button (click)="cancelFn()" class="btn btn-light mr-2">
    <i class="la la-lg la-ban"></i>
    cancel
  </button>
  <button (click)="callbackFn()" class="btn btn-taamana">
    <i class="las la-lg la-thumbs-up"></i>
    support
  </button>
</div>
