import {Injectable} from '@angular/core';
import {UserModel, WalletModel} from '../models';
import {QueryService} from './query.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {firstValueFrom, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  user: UserModel;
  wallet: WalletModel;
  host: WalletModel;
  token: string;

  constructor(
    private queryService: QueryService
  ) {
  }

  init(): void {
    this.user = this.user === undefined ? new UserModel({id: 0}) : this.user;
    this.wallet = this.wallet === undefined ? new WalletModel({id: 0}) : this.wallet;
  }

  async checkToken(token: string): Promise<any> {
    const $q = this.queryService.q('POST', '/register/check_token', {token});
    return await firstValueFrom($q);
  }

  async checkEmail(email: string): Promise<any> {
    const $q = this.queryService.q('POST', '/register/check_email', {email});
    return await firstValueFrom($q);
  }

  register(): Observable<any> {
    const payload = {
      user: this.user,
      wallet: this.wallet,
      host: this.host,
      token: this.token
    };
    return this.queryService.q(
      'POST',
      environment.apiUrl + '/register',
      payload
    );
  }
}
