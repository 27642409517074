import {Injectable} from '@angular/core';
import {ActionModel, ShareModel, WalletModel} from '../models';

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  share: ShareModel;

  constructor(
  ) {
  }

  /**
   * @param action
   * @param owner
   * @param seller
   */
  createShare(
    action: ActionModel,
    owner: WalletModel,
    seller: WalletModel
  ): ShareModel {
    return new ShareModel({
      id: 0,
      stars: 0,
      message: '',
      qty: 1,

      owner: owner.id,
      seller: seller.id,
      action: action.id,
    });
  }
}
