import {Component, OnInit, Output, EventEmitter, NgZone, Input, ViewChild, ElementRef} from '@angular/core';
import {ActionModel, GoogleMaps} from '../../../../../shared/models';
import {MouseEvent} from '@agm/core';
import {MapsAPILoader} from '@agm/core';

@Component({
  selector: 'app-wallet-actions-edit-location',
  templateUrl: './location.component.html',
})
export class WalletActionsEditLocationComponent implements OnInit {
  @Input() action: ActionModel;
  @Input() activeTab?: string;
  @Output() locationData = new EventEmitter<any>();

  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  countryShortCode: string;

  private geoCoder;

  readonly defaultZoomLevel: number = 4;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  /**
   * @param mapsAPILoader
   * @param ngZone
   */
  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
  }

  ngOnInit(): void {
    if (this.activeTab !== 'location' || this.action.id === 0) {
      return;
    }
    this.listenGoogleMapAddressChange();
  }

  /**
   *
   * @private
   */
  private listenGoogleMapAddressChange(): void {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();
      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.getAddress(this.latitude, this.longitude);
          this.zoom = this.defaultZoomLevel;
        });
      });
    });
  }

  /**
   *
   * @param $event
   */
  mapClicked($event: MouseEvent): void {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress($event.coords.lat, $event.coords.lng);
  }

  /**
   *
   * @private
   */
  private setCurrentLocation(): void {
    this.zoom = this.defaultZoomLevel;
    if (
      this.action.latitude &&
      this.action.longitude &&
      this.action.address
    ) {
      this.latitude = +this.action.latitude;
      this.longitude = +this.action.longitude;
      this.address = this.action.address;
    } else if (
      'geolocation' in navigator
    ) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  /**
   *
   * @param latitude
   * @param longitude
   */
  getAddress(latitude, longitude): void {
    this.geoCoder.geocode(
      {location: {lat: latitude, lng: longitude}},
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            const address_components = results[0].address_components;
            const countryObj = address_components.find(el => el.types[0] === 'country');
            this.countryShortCode = countryObj?.short_name;
            this.address = results[0].formatted_address;
            const mapData: GoogleMaps = {
              latitude: this.latitude,
              longitude: this.longitude,
              address: this.address,
              countryShortCode: this.countryShortCode,
            };
            this.locationData.emit(mapData);
          }
        }
      }
    );
  }
}
