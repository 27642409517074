<div class="content">
  <div class="row">
    <div class="col-12">
      <h2 class="font-weight-bold">Create your account</h2>
    </div>
    <div class="col-12 mt-3">
      <label>Email</label>
      <div>
        <input class="form-control" type="text">
      </div>
    </div>
    <div class="col-12  mt-3">
      <button class="btn btn-lg btn-taamana w-100" routerLink="/reg">Continue with email</button>
    </div>
    <div class="col-12 mt-3">
      <div class="notice">
        By signing in or creating an account, you agree with our <a href="#">Term & Conditions</a> and <a href="#">Privacy
        Statement</a>
      </div>
    </div>
  </div>
</div>
