import { Component, OnDestroy } from '@angular/core';
import { AuthService, CatalogService, QueryService, WalletService, RatingService, NotificationsService } from 'src/app/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { WalletModel } from '../../../shared/models';

@Component({
  selector: 'app-wallet-ratings-new',
  templateUrl: './new.component.html'
})
export class WalletRatingsNewComponent implements OnDestroy {
  wallet: WalletModel;

  loaderSubscription$: Subscription;

  // UI Flag
  isLoading = true;
  isSubmitted = false;

  /**
   * @param authService
   * @param route
   * @param queryService
   * @param translateService
   * @param router
   * @param catalogService
   * @param walletService
   * @param ratingService
   * @param notificationService
   */
  constructor(
    private route: ActivatedRoute,
    private queryService: QueryService,
    private translateService: TranslateService,
    private router: Router,
    private catalogService: CatalogService,
    private walletService: WalletService,
    private ratingService: RatingService,
    private notificationService: NotificationsService,
    // public
    public authService: AuthService
  ) {
    this.loaderSubscription$ = this.route.params.subscribe(async params => {
      this.loadItem(+params.id);
    });
  }

  ngOnDestroy(): void {
    this.loaderSubscription$.unsubscribe();
  }

  /**
   * @param walletId
   * @param actionId
   */
  async loadItem(walletId: number): Promise<void> {
    this.isLoading = true;

    // Load Wallet
    this.wallet = new WalletModel(await this.walletService.loadWallet(walletId));

    // Load Rating
    if (this.ratingService.getRating() === undefined) {
      if (!await this.ratingService.initRating(
        this.wallet,
        this.authService.getId()
      )) {
        await this.router.navigate(['wallets/' + this.wallet.id + '/ratings/noactions']);
      }
    }

    this.isLoading = false;
  }
}
