<div class="content">

  <div class="row">
    <div class="col-12 d-flex flex-column justify-content-center">
      <div>
        <span class="small text-muted">You are invited by</span>
      </div>
      <div class="border rounded bg-white p-2">
        <app-wallet-header
          [wallet]="registerService.host"
          [showFollow]="false"
          [size]="'md'"></app-wallet-header>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <h2 class="font-weight-bold">Profile information</h2>
    </div>
    <div class="col-12">
      <div class="small text-muted">
        A profile can represent an individual or a group, and you can create multiple Profiles if you want to.
        Let's create your first Profile !
      </div>
    </div>

    <div class="col-12 mt-3">
      <label>Profile type</label>
      <div class="row">
        <div class="col-6">
          <input name="type" [(ngModel)]="registerService.wallet.type" type="radio" id="type_1" [value]="1">
          <label for="type_1" class="checkbox">Individual</label>
        </div>
        <div class="col-6">
          <input name="type" [(ngModel)]="registerService.wallet.type" type="radio" id="type_2" [value]="2">
          <label for="type_2" class="checkbox">Group</label>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3">
      <label>Profile name</label>
      <div>
        <input [(ngModel)]="registerService.wallet.name" class="form-control" type="text">
      </div>
    </div>
    <div class="col-12 mt-3">
      <label>Description</label>
      <div>
        <input [(ngModel)]="registerService.wallet.description" class="form-control" type="text">
      </div>
    </div>
    <div class="col-12 mt-3">
      <label>Location</label>
      <div>
        <ngx-select
          optionTextField="name"
          optionValueField="code"
          [items]="countries"
          [(ngModel)]="registerService.wallet.country"
        ></ngx-select>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12 d-flex justify-content-center">
      <button [disabled]="isDisabled()" class="btn wide btn-sm btn-taamana" (click)="register()">
        Submit
        <i class="la la-arrow-right"></i>
      </button>
    </div>
  </div>
</div>
