import {Component, OnInit} from '@angular/core';
import { RegisterService } from '../../../shared/services';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-reg-step-3',
  templateUrl: './step.component.html',
})
export class RegStepPersoComponent implements OnInit {

  modeHide = true;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    public registerService: RegisterService
  ) {
  }

  ngOnInit(): void {
    if (!this.registerService.token) {
      this.router.navigate(['/register/code']);
    }
  }

  async checkEmail(): Promise<void> {
    const ret = await this.registerService.checkEmail(this.registerService.user.email);
    if (ret.check === true) {
      this.router.navigate(['/register/profile']);
    } else {
      this.toastr.error(ret.msg);
    }
  }


  isDisabled(): boolean {
    return !this.registerService.user.firstName ||
      !this.registerService.user.lastName ||
      !this.registerService.user.email ||
      !this.registerService.user.password;
  }

  toggleModeHide(): void {
    this.modeHide = !this.modeHide;
  }
}
