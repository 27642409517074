import { Component } from '@angular/core';
import { RegisterService } from '../../../shared/services';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WalletModel } from '../../../shared/models';

@Component({
  selector: 'app-reg-code',
  templateUrl: './code.component.html',
})
export class RegStepCodeComponent {
  countries: any;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    public registerService: RegisterService
  ) {
  }

  async checkCode(): Promise<void> {
    const ret = await this.registerService.checkToken(this.registerService.token);
    if (ret.check === true) {
      this.registerService.host = new WalletModel(ret.host);
      this.router.navigate(['/register/perso']);
    } else {
      this.registerService.token = '';
      this.toastr.error(ret.msg);
    }
  }

  isDisabled(): boolean {
    return !this.registerService.token;
  }
}
