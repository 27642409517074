<app-header></app-header>

<div class="container mt-3">
  <div class="row justify-content-center">

    <div *ngIf="isConnected" class="col-md-5 col-lg-4">
      <div class="sticky-top">
        <app-wallet-menu></app-wallet-menu>
      </div>
    </div>

    <div [ngClass]="{'col-md-7 col-lg-8': isConnected, 'col-10': !isConnected}">
      <router-outlet></router-outlet>
    </div>

  </div>
</div>
