import { environment } from 'src/environments/environment';

import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule, LOCATION_INITIALIZED } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { NgxSelectModule } from 'ngx-select-ex';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { ToastrModule } from 'ngx-toastr';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';

import { NouisliderModule } from 'ng2-nouislider';

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

import {
  QueryService,
  RegisterService,
  CatalogService,
  Broadcaster,
  RatingService,
  MetricService,
  ShareService,
  NotificationsService
} from './shared/services';

import {
  MainComponent,
  WelcomeComponent,
  LoginComponent,
  RegStepComponent,
  RegStepCodeComponent,
  RegStepLocationComponent,
  RegStepThemesComponent,
  RegStepPersoComponent,
  RegStepProfileComponent,
  RegStepConfirmComponent
} from './main';

import {
  PublicComponent,
  NotFoundComponent,

  ActionDetailsComponent,
  ActionSupportFormComponent,
  WalletDepositFormComponent,
  WalletDepositSuccessComponent,
  WalletDepositFailureComponent,
  ActionSupportThanksComponent,
  SupportConfirmModalComponent,

  WalletCardComponent,
  WalletsListComponent,
  WalletsEditComponent,
  WalletTokensComponent,
  WalletsSimilarComponent,
  WalletDetailsComponent,
  WalletMenuComponent,
  WalletTransactionsListComponent,
  WalletTransactionsDepositModalComponent,
  WalletTransactionsWithdrawModalComponent,

  WalletActionsListComponent,
  WalletActionsEditComponent,
  WalletActionsEditLocationComponent,
  WalletActionsSubmitComponent,
  WalletActionsConfirmComponent,

  WalletRatingsListComponent,
  WalletRatingsNewComponent,
  WalletRatingsActionComponent,
  WalletRatingsEfficiencyComponent,
  WalletRatingsEffectivenessComponent,
  WalletRatingsQualityComponent,
  WalletRatingsCommongoodsComponent,
  WalletRatingsVideoqualityComponent,
  WalletRatingsCommentComponent,
  WalletRatingsSummaryComponent,
  WalletRatingsConfirmComponent,
  WalletRatingsNoactionsComponent
} from './pub';

import {
  DashboardComponent,
  DashboardMenuComponent,
  DashboardTopMetricsSoldSharesComponent
} from './dashboard';

import {
  ActionCardComponent,
  ActionHeaderComponent,
  ActionScoreComponent,
  ActionStarsComponent,
  ActionStarsRatingComponent,
  AvatarComponent,
  HeaderComponent,
  HeaderRegLeftComponent,
  HeaderRegRightComponent,
  MediaUploadComponent,
  LoaderComponent,
  PageTitleComponent,
  FileUploadComponent,
  FileDisplayComponent,
  WalletHeaderComponent,
  ShareCardComponent
} from './shared/components';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// tslint:disable-next-line:typedef
export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = 'en';
      translate.setDefaultLang('en');
      translate.use(langToSet).subscribe(() => {
        // console.log(`Successfully initialized '${langToSet}' language.'`);
      }, err => {
        // console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}

@NgModule({
  declarations: [
    AppComponent,

    // MAIN
    MainComponent,
    WelcomeComponent,
    LoginComponent,
    NotFoundComponent,
    RegStepComponent,
    RegStepCodeComponent,
    RegStepLocationComponent,
    RegStepThemesComponent,
    RegStepPersoComponent,
    RegStepProfileComponent,
    RegStepConfirmComponent,

    // SHARED
    ActionCardComponent,
    ActionHeaderComponent,
    ActionScoreComponent,
    ActionStarsComponent,
    ActionStarsRatingComponent,
    AvatarComponent,
    HeaderComponent,
    HeaderRegLeftComponent,
    HeaderRegRightComponent,
    MediaUploadComponent,
    LoaderComponent,
    PageTitleComponent,
    FileUploadComponent,
    FileDisplayComponent,
    WalletHeaderComponent,
    ShareCardComponent,

    // PUBLIC
    PublicComponent,

    // ACTIONS
    ActionDetailsComponent,
    ActionSupportFormComponent,
    WalletDepositFormComponent,
    WalletDepositSuccessComponent,
    WalletDepositFailureComponent,
    ActionSupportThanksComponent,
    SupportConfirmModalComponent,

    // WALLETS
    WalletCardComponent,
    WalletsListComponent,
    WalletsEditComponent,
    WalletsSimilarComponent,
    WalletDetailsComponent,
    WalletMenuComponent,
    WalletTransactionsListComponent,
    WalletTransactionsDepositModalComponent,
    WalletTransactionsWithdrawModalComponent,
    WalletTokensComponent,

    // WALLET
    WalletActionsListComponent,
    WalletActionsEditComponent,
    WalletActionsEditLocationComponent,
    WalletActionsSubmitComponent,
    WalletActionsConfirmComponent,

    // RATE
    WalletRatingsListComponent,
    WalletRatingsNewComponent,
    WalletRatingsActionComponent,
    WalletRatingsEfficiencyComponent,
    WalletRatingsEffectivenessComponent,
    WalletRatingsQualityComponent,
    WalletRatingsCommongoodsComponent,
    WalletRatingsVideoqualityComponent,
    WalletRatingsCommentComponent,
    WalletRatingsSummaryComponent,
    WalletRatingsConfirmComponent,
    WalletRatingsNoactionsComponent,

    // DASHBOARD
    DashboardComponent,
    DashboardMenuComponent,
    DashboardTopMetricsSoldSharesComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxSelectModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsKey,
      libraries: ['places']
    }),
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsKey),
    TabsModule.forRoot(),
    ToastrModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    FormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    FileUploadModule,
    NouisliderModule,
    ShareButtonsModule,
    ShareIconsModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    QueryService,
    CatalogService,
    RegisterService,
    Broadcaster,
    RatingService,
    MetricService,
    ShareService,
    NotificationsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
