import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService, Broadcaster, CatalogService, QueryService, WalletService} from 'src/app/shared/services';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {WalletModel} from '../../../shared/models';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-pub-wallets-edit',
  templateUrl: './edit.component.html'
})
export class WalletsEditComponent implements OnInit {
  wallet: WalletModel;

  countries: any;

  // form instance
  form!: FormGroup;
  loaderSubscription$: Subscription;

  // UI Flag
  isLoading = true;
  isSubmitted = false;

  /**
   * @param authService
   * @param walletService
   * @param route
   * @param queryService
   * @param translateService
   * @param formBuilder
   * @param router
   * @param toastrService
   * @param broadcaster
   */
  constructor(
    public authService: AuthService,
    public walletService: WalletService,
    public route: ActivatedRoute,
    public queryService: QueryService,
    public translateService: TranslateService,
    public formBuilder: FormBuilder,
    private router: Router,
    private toastrService: ToastrService,
    private broadcaster: Broadcaster,
    private catalogService: CatalogService
  ) {
    this.loaderSubscription$ = this.route.params.subscribe(async params => {
      this.loadItem(+params.id);
    });
  }

  async ngOnInit(): Promise<void> {
    this.countries = await this.catalogService.getAllCountries();
  }

  /**
   * @param walletId
   */
  async loadItem(walletId: number): Promise<void> {
    this.isLoading = true;
    let tmpWallet = {};
    if (walletId > 0) {
      tmpWallet = await this.walletService.loadWallet(walletId);
    }
    this.wallet = new WalletModel(tmpWallet);

    // Build for
    this.form = this.getForm(this.wallet);

    this.isLoading = false;
  }

  /**
   * @param wallet
   */
  getForm(wallet: WalletModel): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(wallet.id),
      name: new FormControl(wallet.name, [Validators.required]),
      description: new FormControl(wallet.description),
      country: new FormControl(wallet.country),
      type: new FormControl(wallet.type),
      color: new FormControl(wallet.color)
    });
  }

  save(): boolean | void {
    if (!this.form || (this.form && !this.form.valid)) {
      return false;

    } else {
      this.isSubmitted = true;

      const payload = _.pickBy(this.form.value, el => el !== null);

      let query;
      if (this.wallet.id === 0) {
        query = this.queryService.q('POST', '/sec/wallets', payload);
      } else {
        query = this.queryService.q('PUT', '/sec/wallets/' + this.wallet.id, payload);
      }

      query?.subscribe(res => {
        this.isSubmitted = false;
        this.loadItem(res.id);
        this.toastrService.success('Saving done !');
        this.broadcaster.broadcast('walletUpdate', {});
        if (this.wallet.id === 0) {
          this.router.navigate(['/wallets/' + res.id + '/edit']);
        }
      }, err => {
        console.log('err: ', err);
        this.isSubmitted = false;
        this.toastrService.error('Ooops !');
      });
    }
  }
}
