import {Injectable} from '@angular/core';
import {QueryService} from './query.service';
import {DataPayloadModel, WalletModel} from '../models';
import * as _ from 'lodash';
import {getResponseData} from '../response';
import {firstValueFrom} from 'rxjs';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  wallets: WalletModel[];

  constructor(
    private queryService: QueryService
  ) {
  }

  async loadUsersWallets(force = false): Promise<WalletModel[]> {
    this.wallets = force || !this.wallets ?
      await this.queryService.q('GET', '/sec/users/wallets').toPromise() :
      this.wallets;
    this.setDefaultWallet(this.wallets);
    return this.wallets;
  }

  addDepositTransaction(sessionId: any, walletId: any): Observable<any> {
    return this.queryService.q('POST', '/sec/transactions/deposit', {sessionId: sessionId, walletId: walletId});
  }

  /**
   *
   * @param wallets
   */
  setDefaultWallet(wallets: WalletModel[]): void {
    const tmpCurrent = this.getCurrentWallet();
    let found: WalletModel;
    if (tmpCurrent) {
      found = _.find(wallets, el => el.id === tmpCurrent.id);
    } else {
      found = wallets[0];
    }
    if (found) {
      this.setCurrentWallet(found);
    }
  }

  /**
   * @param wallet
   * @return WalletModel
   */
  setCurrentWallet(wallet: WalletModel): WalletModel {
    localStorage.setItem('t_w', JSON.stringify(wallet));
    return wallet;
  }

  /**
   * @returns WalletModel
   */
  getCurrentWallet(): WalletModel {
    return JSON.parse(localStorage.getItem('t_w'));
  }

  resetWallets(): void {
    this.wallets = undefined;
    localStorage.removeItem('t_w');
  }

  /**
   *
   * @param payload
   * @returns
   */
  async loadWalletsBy(payload: {}): Promise<WalletModel[]> {
    const $q = this.queryService.q('POST', '/pub/wallets/by', payload);
    return await firstValueFrom($q);
  }

  /**
   * @param walletId
   */
  async loadSimilarWallets(walletId: number): Promise<WalletModel[]> {
    const $q = this.queryService.q('GET', '/pub/wallets/' + walletId + '/similar');
    return await firstValueFrom($q);
  }

  /**
   * @param walletId number
   */
  async loadWallet(walletId: number): Promise<any> {
    const $q = this.queryService.q('GET', '/pub/wallets/' + walletId);
    return await firstValueFrom($q);
  }

  /**
   * @param walletId
   */
  async loadStream(walletId: number): Promise<any[]> {
    const $q = this.queryService.q('GET', '/pub/wallets/' + walletId + '/stream');
    return await firstValueFrom($q);
  }

  /**
   * @param walletId
   */
  async loadProducedActions(walletId: number): Promise<any[]> {
    const $q = this.queryService.q('GET', '/sec/wallets/' + walletId + '/produced');
    return await firstValueFrom($q);
  }

  /**
   * @param walletId
   * @param actionId
   */
  async loadAction(walletId: number, actionId: number): Promise<any> {
    const $q = this.queryService.q('GET', '/pub/wallets/' + walletId + '/actions/' + actionId);
    return await firstValueFrom($q);
  }

  /**
   * @param walletId
   */
  async loadActionToValidate(walletId: number): Promise<any> {
    const $q = this.queryService.q('GET', '/sec/ratings/findaction');
    return getResponseData(await firstValueFrom($q), true);
  }

  /**
   * @param walletId
   * @param actionId
   */
  async loadSecuredAction(walletId: number, actionId: number): Promise<any> {
    const $q = this.queryService.q('GET', '/sec/wallets/' + walletId + '/actions/' + actionId);
    return await firstValueFrom($q);
  }

  /**
   * @param walletId
   */
  async loadTransactions(walletId: number): Promise<any[]> {
    const $q = this.queryService.q('GET', '/sec/wallets/' + walletId + '/transactions');
    return await firstValueFrom($q);
  }

  /**
   * @param walletId
   */
  async loadNetwork(walletId: number): Promise<DataPayloadModel> {
    const $q = this.queryService.q('GET', '/sec/wallets/' + walletId + '/network');
    return getResponseData(await firstValueFrom($q), false);
  }

  /**
   *
   * @param walletId
   */
  async loadRatings(walletId: number): Promise<any[]> {
    const $q = this.queryService.q('GET', '/sec/wallets/' + walletId + '/ratings');
    return await firstValueFrom($q);
  }

  /**
   *
   * @param actionId
   */
  async loadActionRatings(actionId: number): Promise<any[]> {
    const $q = this.queryService.q('GET', '/sec/actions/' + actionId + '/ratings');
    return await firstValueFrom($q);
  }

  /**
   *
   * @param actionId
   */
  async loadActionShares(actionId: number): Promise<any[]> {
    const $q = this.queryService.q('GET', '/sec/actions/' + actionId + '/shares');
    return await firstValueFrom($q);
  }

  /**
   *
   * @param walletId
   * @param ratingId
   */
  async loadRating(walletId: number, ratingId: number): Promise<any[]> {
    const $q = this.queryService.q('GET', '/sec/wallets/' + walletId + '/ratings/' + ratingId);
    return await firstValueFrom($q);
  }

  /**
   *
   * @param wallet
   * @param target
   */
  async follow(wallet: WalletModel, target: WalletModel): Promise<any> {
    const $q = this.queryService.q('GET', '/sec/wallets/' + wallet.id + '/follow/' + target.id);
    return await firstValueFrom($q);
  }
}
