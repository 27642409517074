import { Component, OnDestroy } from '@angular/core';
import { ActionModel, RatingModel, WalletModel } from '../../../shared/models';
import { Subscription } from 'rxjs';
import { AuthService, QueryService, WalletService, CatalogService, NotificationsService } from '../../../shared/services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-action-details',
  templateUrl: './details.component.html'
})
export class ActionDetailsComponent implements OnDestroy {
  isAdmin = false;
  isConnected = false;

  // Current Action
  action: ActionModel;
  // Wallet referencing this action
  wallet: WalletModel;
  // Current wallet context (user selected)
  walletCurrent: WalletModel;

  //
  ratings: RatingModel[] = [];
  shares: any = [];
  // supports: SupportModel = [];

  values: any = [];
  country: { name: string; code: string; };
  focusedFile: any;

  loaderSubscription$: Subscription;

  // Ui
  isLoading = true;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private queryService: QueryService,
    private catalogService: CatalogService,
    private notificationService: NotificationsService,
    // used in template
    public walletService: WalletService,
  ) {
    this.loaderSubscription$ = this.route.params.subscribe(async params => {
      this.loadItem(+params.wallet, +params.id);
    });
    this.isAdmin = this.authService.isAdmin();
    this.isConnected = this.authService.isConnected();
    this.walletCurrent = this.walletService.getCurrentWallet();
  }

  ngOnDestroy(): void {
    this.loaderSubscription$.unsubscribe();
  }

  async loadItem(walletId: number, itemId: number): Promise<void> {
    this.isLoading = true;

    // Load data & catalog data
    this.action = await this.walletService.loadAction(walletId, itemId);

    this.wallet = await this.walletService.loadWallet(walletId);
    this.values = await this.catalogService.getValues();
    this.country = await this.catalogService.getCountry(this.action.country);

    this.ratings = await this.walletService.loadActionRatings(itemId);
    this.shares = await this.walletService.loadActionShares(itemId);

    this.isLoading = false;

    await this.notificationService.seeAction(this.wallet.id, this.action.id);
  }

  /**
   * @param file
   */
  setFocusedFile(file): void {
    this.focusedFile = file;
  }
}
