<div class="row">
  <div class="col">

    <!-- header -->
    <div class="card mb-3">
      <div class="card-body border-bottom">
        <div class="d-flex align-items-center">
          <h4 class="font-weight-bold mb-0">
            Thank you for supporting !
          </h4>
        </div>
      </div>

      <!-- content -->
      <div class="card-body">
        <p class="card-text">
          Thanks for your support ! And i you think that this community deserve even more support,
          please spread the info by sharing your profile on social media !
        </p>
      </div>
    </div>
  </div>
</div>
