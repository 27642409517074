import { Injectable } from '@angular/core';
import { QueryService } from './query.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MetricService {

  constructor(
    private queryService: QueryService
  ) {
  }

  /**
   * Sample Function
   */
  async loadMetric(payload: any): Promise<any> {
    return await this.queryService.q('POST', '/metrics/url_to_metric', payload).toPromise();
  }

  async getTopMetricsSoldSharesData(): Promise<any> {
    return await this.queryService.q('POST', '/sec/metrics/top-metrics-sold-shares').toPromise();
  }
}
