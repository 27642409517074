<div
  (click)="goToDetails($event)"
  class="card action link">
  <div class="card-body">

    <div class="row">

      <!-- header -->
      <div class="col-12 mb-2">
        <app-action-header
          [action]="action"
          [wallet]="wallet"
          [size]="'sm'"
          [showStatus]="false"
          [showSupportButton]="false"
          [showWalletBackLink]="false"
          [isNew]="isNew"
        ></app-action-header>
      </div>

      <!-- video -->
      <div class="col-12">
        <ng-container *ngIf="action.files.length>0">
          <app-file-display [file]="action.files[0]" [cssClass]="'file max'"></app-file-display>
        </ng-container>
        <ng-container *ngIf="action.files.length===0">
          <img alt="action" class="file md rounded" src="../../../../assets/img/action.jpg">
        </ng-container>
      </div>

      <!-- content -->
      <div class="col-12">

        <!-- followers & shares -->
        <div class="d-flex justify-content-between align-items-center flex-row mt-2">

          <div class="d-flex flex-wrap">
            <div class="p-1 px-2 rounded bg-light small mr-2">
              <i class="la la-globe la-lg mr-1"></i>
              <span>{{ country?.name }}</span>
            </div>
            <div *ngFor="let theme of action?.themes" class="p-1 px-2 rounded bg-light small mr-2">
              <i class="la la-lg {{theme.icon}} mr-1"></i>
              <span>{{theme.name}}</span>
            </div>
            <div class="p-1 px-2 rounded bg-light small mr-2">
              <i class="la la-dollar la-lg mr-1"></i>
              <span>{{action?.valueLevel}} budget</span>
            </div>
            <div *ngIf="action?.remaining > 0" class="p-1 px-2 rounded bg-light small mr-2">
              <i class="las la-money-check la-lg mr-1"></i>
              <span>{{action?.remaining}} shares</span>
            </div>
            <div *ngIf="action?.remaining === 0" class="p-1 px-2 rounded bg-warning small mr-2">
              <i class="las la-money-check la-lg mr-1"></i>
              <span>sold out !</span>
            </div>
          </div>
        </div>

        <!-- bio -->
        <div class="card-text mdtext mt-2">
          <ng-container *ngIf="action?.description.length > 100">{{ action?.description | slice:0:100}} ...</ng-container>
          <ng-container *ngIf="action?.description.length <= 100">{{ action?.description }}</ng-container>
        </div>

        <!-- -->
        <div *ngIf="action?.supporters.length>0"  class="d-flex align-items-center flex-row mt-2">
          <div *ngFor="let supporter of action?.supporters" class="mr-2">
            <app-wallet-header
              [wallet]="supporter"
              [size]="'xs'"
              [showLink]="true"
              [showFollow]="false"
              [showName]="false"
              [showScore]="false"></app-wallet-header>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
