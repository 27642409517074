<ng-container *ngIf="!isLoading">

  <div class="row">
    <div class="col">

      <!-- header -->
      <div class="card mb-3">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <h4 class="font-weight-bold mb-0">
              Validation
            </h4>
          </div>
        </div>
      </div>

      <!-- content -->
      <div class="card mb-3">
        <div class="card-body">
          <h6 class="card-title">
            Welcome to our validation process
          </h6>

          <p class="card-text">
            You are now going to evaluate a randomly selected action on several criterias.
          </p>

          <p class="card-text">
            You have to rate each criteria based on your own experience and understanding of the action.
            Keep in mind that there is no good or bad answers.
          </p>

          <p class="card-text">
            Take your time, be honest in your reviews and don't be afraid to make mistakes : we all have to start
            somewhere !
          </p>

          <!-- buttons -->
          <div class="d-flex justify-content-around align-items-center">
            <div>
              <button [routerLink]="'/wallets/' + wallet.id + '/ratings'"
                      class="btn btn-sm btn-light">
                <i class="la la-arrow-left"></i> back to validations list
              </button>
            </div>

            <button class="btn btn-sm btn-taamana" [routerLink]="'/wallets/' + wallet.id + '/ratings/action'">
              Start
              <i class="la la-arrow-right"></i>
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>

</ng-container>
