import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AuthService } from './services';

@Injectable({
  providedIn: 'root'
})
export class AccessRightsResolver implements Resolve<any> {

  constructor(
    public auth: AuthService,
    public router: Router,
  ) {}

  resolve(route: ActivatedRouteSnapshot): any {
    if (this.auth.isAdmin()) {
      return;
    }
    this.router.navigate(['/not-found']);
  }
}