import { Component } from '@angular/core';
import { AuthService, QueryService, CatalogService, WalletService } from '../../../shared/services';
import { WalletModel } from '../../../shared/models';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-wallet-ratings',
  templateUrl: './list.component.html'
})
export class WalletRatingsListComponent {
  wallet: WalletModel;
  ratings: any = [];

  loaderSubscription$: Subscription;

  isLoading = true;

  constructor(
    public authService: AuthService,
    public route: ActivatedRoute,
    public queryService: QueryService,
    private catalogService: CatalogService,
    private walletService: WalletService
  ) {
    this.loaderSubscription$ = this.route.params.subscribe(async params => {
      this.isLoading = true;
      await this.loadWallet(+params.id);
      await this.loadRatings(+params.id);
      this.isLoading = false;
    });
  }

  async loadWallet(walletId: number): Promise<void> {
    this.wallet = new WalletModel(await this.walletService.loadWallet(walletId));
  }

  async loadRatings(walletId: number): Promise<void> {
    this.ratings = await this.walletService.loadRatings(walletId);
  }
}
