import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {merge, Observable, of, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AuthService} from './auth.service';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class QueryService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private router: Router
  ) { }

  queryWithProgressBar(method: string, url: string, payload = {}): Observable<any> {
    // Add env apiUrl if server not found
    if (url.indexOf('http') === -1) {
      url = environment.apiUrl + url;
    }

    return this.httpClient.request(
      method,
      url,
      {
        headers: new HttpHeaders('Authorization: Bearer ' + this.authService.getToken()),
        body: payload,
        reportProgress: true,
        observe: 'events'
      },
      
    ).pipe(
      // tap(_ => console.log('Fetched List')),
      catchError(this.handleError('DataService::list', []))
    );
  }

  q(method: string, url: string, payload = {}): Observable<any> {
    // Add env apiUrl if server not found
    if (url.indexOf('http') === -1) {
      url = environment.apiUrl + url;
    }

    // Do other Request
    return this.httpClient.request(
      method,
      url,
      {
        headers: new HttpHeaders('Authorization: Bearer ' + this.authService.getToken()),
        body: payload
      }
    ).pipe(
      // tap(_ => console.log('Fetched List')),
      catchError(this.handleError('DataService::list', []))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<any> {
    return (error: any): Observable<T> => {
      if (error.status && error.status === 401) {
        this.authService.logout();
      } else if (error.status === 404) {
        this.router.navigate(['/not-found']);
      }

      console.error(error); // log to console instead
      console.log(`${operation} failed: ${error.message} - ${error.status}`);

      return merge(throwError(error), of(result as T));
    };
  }

}
