<ng-container *ngIf="!isLoading">
  <div class="row">
    <div class="col">

      <!-- header -->
      <div class="card mb-3">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <h4 class="font-weight-bold mb-0">
              Validation
            </h4>
          </div>
        </div>
      </div>

      <!-- content -->
      <div class="card mb-3">
        <div class="card-body">
          <h6 class="card-title">
            No action to validate !
          </h6>

          <p class="card-text">
            Sorry but no validation is required at the moment.
          </p>

          <p class="card-text">
            Please come back in a moment !
          </p>

          <!-- buttons -->
          <div class="d-flex justify-content-around align-items-center">
            <div>
              <button [routerLink]="'/wallets/' + wallet.id + '/ratings'"
                      class="btn btn-sm btn-light">
                <i class="la la-arrow-left"></i> back to validations list
              </button>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</ng-container>

<app-loader *ngIf="isLoading"></app-loader>
