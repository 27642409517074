import {Component, OnInit} from '@angular/core';
import {CatalogService, QueryService, RegisterService} from '../../../shared/services';
import * as _ from 'lodash';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-reg-step-2',
  templateUrl: './step.component.html',
})
export class RegStepThemesComponent implements OnInit {

  themes: any = [];

  constructor(
    private registerService: RegisterService,
    private queryService: QueryService,
    private catalogService: CatalogService,
    private router: Router
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.themes = await this.catalogService.getAllThemes();
  }

  isDisabled(): boolean {
    return this.registerService.user.themes.length === 0;
  }

  setTheme(theme: any): void {
    if (_.filter(this.registerService.user.themes, el => {
      return el.name === theme.name;
    }).length === 0) {
      this.registerService.user.themes.push(theme);
    } else {
      this.registerService.user.themes = _.filter(this.registerService.user.themes, el => {
        return el.name !== theme.name;
      });
    }
  }

  haveTheme(theme: any): boolean {
    return _.filter(this.registerService.user.themes, el => {
      return el.name === theme.name;
    }).length > 0;
  }

  submit(): void {
    const payload = {
      user: this.registerService.user,
      wallet: this.registerService.wallet
    };
    this.queryService.q(
      'POST',
      environment.apiUrl + '/register',
      payload
    ).subscribe(
      evt => {
        this.router.navigate(['/register/confirm']);
      },
      err => {
        console.log(err);
      }
    );
  }
}
