import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessRightsResolver } from './shared/custom.resolver';

import {
  PublicComponent,
  NotFoundComponent,

  // ACTIONS
  ActionDetailsComponent,
  ActionSupportFormComponent,
  WalletDepositFormComponent,
  WalletDepositSuccessComponent,
  WalletDepositFailureComponent,
  ActionSupportThanksComponent,

  // WALLETS
  WalletsListComponent,
  WalletsEditComponent,
  WalletDetailsComponent,
  WalletTransactionsListComponent,
  WalletActionsListComponent,
  WalletActionsEditComponent,
  WalletActionsEditLocationComponent,
  WalletActionsSubmitComponent,
  WalletActionsConfirmComponent,
  WalletTokensComponent,

  WalletRatingsListComponent,
  WalletRatingsNewComponent,
  WalletRatingsActionComponent,
  WalletRatingsEfficiencyComponent,
  WalletRatingsEffectivenessComponent,
  WalletRatingsQualityComponent,
  WalletRatingsCommongoodsComponent,
  WalletRatingsVideoqualityComponent,
  WalletRatingsCommentComponent,
  WalletRatingsSummaryComponent,
  WalletRatingsConfirmComponent,
  WalletRatingsNoactionsComponent,

} from './pub';

import {
  // DASHBOARD
  DashboardComponent,
  DashboardTopMetricsSoldSharesComponent
} from './dashboard';

import {
  MainComponent,
  LoginComponent,
  RegStepComponent,
  RegStepCodeComponent,
  RegStepLocationComponent,
  RegStepPersoComponent,
  RegStepProfileComponent,
  RegStepThemesComponent,
  RegStepConfirmComponent
} from './main';


// import {
//   AuthGuard,
//   AuthAdminGuard
// } from './shared/services';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'public',
    pathMatch: 'full'
  },
  {
    path: 'main',
    component: MainComponent,
    children: [
      {
        path: '',
        redirectTo: 'welcome',
        pathMatch: 'full'
      },
      {
        path: 'welcome',
        component: LoginComponent
      },
      {
        path: 'login',
        component: LoginComponent
      },
    ]
  },
  {
    path: 'register',
    component: RegStepComponent,
    children: [
      {
        path: '',
        redirectTo: 'code',
        pathMatch: 'full'
      },
      {
        path: 'code',
        component: RegStepCodeComponent
      },
      {
        path: 'location',
        component: RegStepLocationComponent
      },
      {
        path: 'themes',
        component: RegStepThemesComponent
      },
      {
        path: 'perso',
        component: RegStepPersoComponent
      },
      {
        path: 'profile',
        component: RegStepProfileComponent
      },
      {
        path: 'confirm',
        component: RegStepConfirmComponent
      },
    ]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    resolve: { access: AccessRightsResolver },
    children: [
      {
        path: '',
        redirectTo: 'top_metrics_sold_shares',
        pathMatch: 'full'
      },
      {
        path: 'top_metrics_sold_shares',
        component: DashboardTopMetricsSoldSharesComponent,
      }
    ]
  },
  {
    path: '',
    component: PublicComponent,
    children: [
      {
        path: '',
        redirectTo: 'wallets',
        pathMatch: 'full'
      },
      {
        path: 'wallets',
        component: WalletsListComponent,
      },
      {
        path: 'wallets/:id',
        component: WalletDetailsComponent,
      },
      {
        path: 'wallets/:wallet/actions/:id',
        component: ActionDetailsComponent,
      },
      {
        path: 'wallets/:wallet/actions/:id/support',
        component: ActionSupportFormComponent,
      },
      {
        path: 'wallets/:wallet/actions/:id/thanks',
        component: ActionSupportThanksComponent,
      },
      {
        path: 'wallets/:id/edit',
        component: WalletsEditComponent
      },
      {
        path: 'wallets/:id/actions',
        component: WalletActionsListComponent
      },
      {
        path: 'wallets/:id/actions/:action/edit',
        component: WalletActionsEditComponent
      },
      {
        path: 'wallets/:id/actions/:action/submit',
        component: WalletActionsSubmitComponent
      },
      {
        path: 'wallets/:id/actions/:action/confirm',
        component: WalletActionsConfirmComponent
      },
      {
        path: 'wallets/:id/transactions',
        component: WalletTransactionsListComponent
      },
      {
        path: 'wallets/:id/transactions/deposit',
        component: WalletDepositFormComponent
      },
      {
        path: 'wallets/:id/transactions/deposit/success',
        component: WalletDepositSuccessComponent
      },
      {
        path: 'wallets/:id/transactions/deposit/failure',
        component: WalletDepositFailureComponent
      },
      {
        path: 'wallets/:id/tokens',
        component: WalletTokensComponent
      },
      {
        path: 'wallets/:id/ratings',
        component: WalletRatingsListComponent
      },
      {
        path: 'wallets/:id/ratings/new',
        component: WalletRatingsNewComponent
      },
      {
        path: 'wallets/:id/ratings/action',
        component: WalletRatingsActionComponent
      },
      {
        path: 'wallets/:id/ratings/efficiency',
        component: WalletRatingsEfficiencyComponent
      },
      {
        path: 'wallets/:id/ratings/effectiveness',
        component: WalletRatingsEffectivenessComponent
      },
      {
        path: 'wallets/:id/ratings/quality',
        component: WalletRatingsQualityComponent
      },
      {
        path: 'wallets/:id/ratings/commongoods',
        component: WalletRatingsCommongoodsComponent
      },
      {
        path: 'wallets/:id/ratings/videoquality',
        component: WalletRatingsVideoqualityComponent
      },
      {
        path: 'wallets/:id/ratings/comment',
        component: WalletRatingsCommentComponent
      },
      {
        path: 'wallets/:id/ratings/summary',
        component: WalletRatingsSummaryComponent
      },
      {
        path: 'wallets/:walletId/ratings/:ratingId/confirm',
        component: WalletRatingsConfirmComponent
      },
      {
        path: 'wallets/:id/ratings/noactions',
        component: WalletRatingsNoactionsComponent
      },
      {
        path: '404',
        component: NotFoundComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
