<div *ngIf="showWalletBackLink" class="d-flex flex-row align-items-center mb-2">
  <a class="" [routerLink]="'/wallets/' + wallet.id">
    <app-avatar [wallet]="wallet" [size]="'xs'" [showName]="true">
      <i class="la la-arrow-left mr-2"></i>
    </app-avatar>
  </a>
</div>

<div class="d-flex flex-row flex-wrap justify-content-between">
  <div class="d-flex flex-wrap align-items-center">
    <span *ngIf="isNew" class="badge badge-danger badge-sm mr-2">new</span>
    <div class="{{ size }} font-weight-bold mr-2">{{ action.name }}</div>
    <ng-container *ngIf="showStatus">
      <div
        *ngIf="action.status > 1"
        class="badge mr-2"
        [ngClass]="{
          'badge-light': action.status === 1,
          'badge-warning': action.status === 2,
          'badge-success': action.status === 3,
          'badge-danger': action.status === 4
        }"
      >
        {{ "status." + action.status | translate }}
      </div>
      <span
        *ngIf="action.status === 1 && action.isPublishable()"
        class="badge badge-success mr-2"
      >
        complete</span
      >
      <span
        *ngIf="action.status === 1 && !action.isPublishable()"
        class="badge badge-light mr-2"
      >
        to complete</span
      >
    </ng-container>
    <div *ngIf="showScore" class="mr-2">
      <app-action-score [score]="action.score" [size]="size"></app-action-score>
    </div>
    <div *ngIf="showStars" class="mr-2">
      <app-action-stars
        [stars]="action.stars"
        [size]="size"
        [showValue]="false"
      ></app-action-stars>
    </div>
  </div>
  <div class="d-flex align-items-center">
<!--    <div *ngIf="showShareButtons" class="ml-auto">-->
<!--      <share-buttons theme="circles-dark" [include]="channels"></share-buttons>-->
<!--    </div>-->
    <div *ngIf="showSupportButton" class="ml-2">
      <button
        [routerLink]="
          '/wallets/' + wallet.id + '/actions/' + action.id + '/support'
        "
        class="btn btn-sm btn-taamana"
      >
        <i class="las la-hands-helping"></i>
        Support
      </button>
    </div>
  </div>
</div>

<p *ngIf="showStamp" class="smaller text-muted">{{ action.stamp }}</p>
