<div class="d-flex flex-row align-items-center justify-content-between">

  <div class="row">

    <div class="col-12 d-flex align-items-center">

      <div *ngIf="showLink" class="d-flex">
        <a [routerLink]="'/wallets/' + wallet.id">
          <app-avatar
            [wallet]="wallet"
            [size]="size"
            [showName]="showName"
            [showBoldName]="showBoldName"
          ></app-avatar>
        </a>
      </div>

      <div *ngIf="!showLink" class="d-flex">
        <app-avatar
          [wallet]="wallet"
          [size]="size"
          [showName]="true"
          [showBoldName]="showBoldName"
        ></app-avatar>
      </div>

      <div *ngIf="showScore" class="d-flex ml-2">
        <div class="score {{ size }} py-1 px-2 text-right">
          <i class="las la-star score"></i> {{ wallet?.score }}
        </div>
      </div>

      <div
        *ngIf="showFollow === true && currentWallet.id !== wallet.id"
        class="d-flex align-items-center ml-2">
        <a
          (click)="follow($event, wallet)"
          class="small taamana cursor-pointer">
          <ng-container *ngIf="!isFollowing">
            <i class="la la-plus-circle"></i> Follow
          </ng-container>
          <ng-container *ngIf="isFollowing">
            <i class="la la-minus-circle"></i> Unfollow
          </ng-container>
        </a>
      </div>

    </div>

<!--    <div class="col-12 d-flex">-->
<!--      <ng-container *ngIf="showShareButtons">-->
<!--        <share-buttons theme="circles-dark" [include]="channels"></share-buttons>-->
<!--      </ng-container>-->
<!--    </div>-->
  </div>

</div>

