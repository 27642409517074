import {Component} from '@angular/core';
import {WalletModel} from 'src/app/shared/models';
import { WalletService, Broadcaster } from 'src/app/shared/services';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-wallet-deposit-success',
  templateUrl: './success.component.html'
})
export class WalletDepositSuccessComponent {
  wallet: WalletModel;
  quantity: number;
  sessionId: string;
  isLoading = true;

   constructor(
    public walletService: WalletService,
    private route: ActivatedRoute,
    private router: Router,
    private broadcaster: Broadcaster
  ) {

   this.route.params.subscribe(async params => {
      this.sessionId  = this.route.snapshot.queryParamMap.get('session_id');
      if(this.sessionId) {
        this.addTransaction(this.sessionId, +params.id);
        console.log(this.sessionId , params);
      } else {
        this.isLoading = false;
      }
    });
  }
  
  async addTransaction(sessionId, walletId) {
    this.isLoading = true;
    this.wallet = await this.walletService.loadWallet(walletId);
    this.walletService.addDepositTransaction(sessionId, this.wallet.id).subscribe((data: any) => {
      this.isLoading = false;
      this.broadcaster.broadcast('walletDura', {});
      this.router.navigate(['/wallets/' + this.wallet.id + '/transactions/deposit/success']);
    })
  }

}
