<div class="stars py-1 px-2 d-flex flex-row align-items-center">
  <div class="d-flex flex-row mr-2">
    <i
      *ngFor="let i of stars;"
      (click)="rate(i)"
      (mouseenter)="hover(i)"
      (mouseleave)="hover(this.rating)"
      [class.text-warning]="i<=hoverRating"
      [class.text-muted]="i>hoverRating"
      class="las la-star la-2x cursor-pointer"></i>
  </div>
  <h5 class="font-weight-bold mb-0 mt-1">{{ hoverRating }}</h5>
</div>
