import {Component,} from '@angular/core';
import {WalletModel} from '../../../../shared/models';
import {WalletService, Broadcaster} from '../../../../shared/services';
import {FormControl} from '@angular/forms';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-wallet-deposit-form',
  templateUrl: './form.component.html'
})
export class WalletDepositFormComponent {

  // Wallet referencing this action
  wallet: WalletModel;

  duraAmount: FormControl = new FormControl(1);
  quantity = 1;

  priceId = environment.stripePriceKey;
  stripePromise = loadStripe(environment.stripePublicKey);


  constructor(
    private broadcaster: Broadcaster,
    // used in template
    public walletService: WalletService,
  ) {

    this.duraAmount.valueChanges.subscribe(qty => {
      this.quantity = qty;
    });
    
  }

  async checkout() {
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await this.stripePromise;
    const result =  stripe.redirectToCheckout({
      mode: 'payment',
      lineItems: [{ price: this.priceId, quantity: this.quantity}],
      successUrl: `${window.location.href}/success?session_id={CHECKOUT_SESSION_ID}`,
      cancelUrl: `${window.location.href}/failure`,
    }).then(function(result) {
      if (result.error) {
        alert(result.error.message);
      };

  })
};

}

