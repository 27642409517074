import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  tokenName = 't_t';
  emailName = 't_e';
  jwtHelper: any;
  roles = ['ROLE_USER', 'ROLE_ADMIN'];

  constructor(
    private router: Router,
    private toastrService: ToastrService
  ) {
    this.jwtHelper = new JwtHelperService();
  }

  /**
   *
   */
  isConnected(): boolean {
    const token = this.getToken();
    return token && !this.jwtHelper.isTokenExpired(token);
  }

  /**
   *
   */
  logout(): void {
    this.clearToken();
    this.router.navigate(['/main/login']);
  }

  isUser(): boolean {
    if (this.isConnected()) {
      return this.getRoles().includes('ROLE_USER');
    }
    return false;
  }

  isAdmin(): boolean {
    if (this.isConnected()) {
      return this.getRoles().includes('ROLE_ADMIN');
    }
    return false;
  }

  getRoles(): string[] {
    const content = this.jwtHelper.decodeToken(this.getToken());
    return content.roles;
  }

  getRolesNames(): string[] {
    return this.roles;
  }

  setToken(token: string): void {
    localStorage.setItem(this.tokenName, token);
  }

  getToken(): string {
    return localStorage.getItem(this.tokenName);
  }

  clearToken(): void {
    localStorage.removeItem(this.tokenName);
  }

  setEmail(email: string): void {
    localStorage.setItem(this.emailName, email);
  }

  getEmail(): string {
    return localStorage.getItem(this.emailName);
  }

  clearEmail(): void {
    localStorage.removeItem(this.emailName);
  }

  getId(): number {
    const content = this.jwtHelper.decodeToken(this.getToken());
    return +content.id;
  }

  getFirstname(): string {
    const content = this.jwtHelper.decodeToken(this.getToken());
    return content.firstName;
  }

  getLastname(): string {
    const content = this.jwtHelper.decodeToken(this.getToken());
    return content.lastName;
  }

  getFullName(): string {
    const content = this.jwtHelper.decodeToken(this.getToken());
    return content.fullName;
  }

  getLastConnection(): string {
    const content = this.jwtHelper.decodeToken(this.getToken());
    return content.lastConnectionD;
  }
}
